* {
  box-sizing: border-box;
  font-family: 'Albert Sans';
}

:root {
  --primary-color: white;
  --background-color: #000000;
  --background-2-color: #121212;
  --background-3-color: #242424;
  --input-background: #1c1c1c;
  --text-color: #ffffff;
  --accent-color: #a546fc;
  --gray-color: #333;
}

.light-mode {
  --primary-color: black;
  --background-color: #ffffff;
  --background-2-color: #f5f5f5;
  --background-3-color: #dddddd;
  --input-background: #fafafa;
  --text-color: #000000;
  --gray-color: #dddddd;
  /* Add more variables as needed */
}

.grecaptcha-badge {
  display: none !important;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  background-color: white;
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
}

a {
  text-decoration: none;
  color: #a546fc;
  cursor: pointer;
}

.hidden {
  display: none;
}

.custom-tooltip {
  background-color: #121212 !important; /* Change the background color */
  color: white; /* Change the text color */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Round the corners */
  font-size: 14px; /* Adjust the font size */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Add a shadow */
  z-index: 9999;
}

/* Arrow customization, if your tooltip has one */
.custom-tooltip .tooltip-arrow {
  color: #121212; /* Match the arrow color with the background */
}

.circle-action {
  width: 100%;
  font-size: 12px !important;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltip-text {
  font-size: 14px;
  visibility: hidden;
  width: 300px;
  background-color: #202020;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 25px 15px;
  position: absolute;
  z-index: 9999 !important;
  top: 125%; /* Adjust this value as needed */
  left: 50%;
  margin-left: -108px; /* Adjust this value as needed */
  margin-top: 20px; /* Adjust this value as needed */
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.9);
}

.tooltip-text p {
  color: white;
  width: 250px;
  margin: auto;
  text-align: center;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -78px;
  border-width: 8px;
  border-style: solid;
  border-color: #202020 transparent transparent transparent;
  transform:rotate(180deg);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.9);
}

/* Close button inside the tooltip */
.tooltip .close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.tt-wrap {
  max-width: 300px;
}

/* Show the tooltip text when the tooltip is visible */
.tooltip.show .tooltip-text {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

/*
/* Show the tooltip text when hovering 
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
*/


.full-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh; /* This will make the container take up the full height of the viewport */
  width: 100%;
  margin: auto;
  text-align: center;
}

.toast {
  width: 600px;
  z-index:9999;
  white-space: pre-wrap;
  margin: auto;
  margin-left: -170px;
}

.Toastify__toast-container {
  min-width: 500px;
  max-width: 600px; /* Adjust the width as needed */
}

.Toastify__toast {
  white-space: pre-wrap; /* Ensures text wraps correctly */
}

button.text-link {
  color: var(--accent-color) !important;
  background: none !important;
  padding: 0px 5px !important;
  width: auto !important;
  margin: 0px !important;
}

.text-link {
  color: var(--accent-color) !important;
  background: none !important;
  padding: 0px !important;
  width: auto !important;
  margin: 0px !important;
}

p {
  color: #a9a9a9;
}

input {
  font-size: 16px !important;
  background-color: var(--input-background);
  -webkit-appearance: none;
}

input {
  font-size: 18px;
  background-color: black;
  border: 1px solid transparent;
  outline: none;
  padding: 15px;
  margin: 5px;
  border-radius: 12px;
  box-shadow: 0 0 0 30px var(--input-background) inset;
  color: var(--primary-color);
}

input:focus {
  border: 1px solid #a546fc;
}

input:active {
  border: 1px solid white;
}

.highlight-text {
  background-color: #a546fc;
  color: white;
  padding: 0px 5px;
}

/* General */

.sign-in-link:hover {
  color: white !important;
}

.lock-icon {
  color: white;
  margin-right: 10px;
}


@media (max-width: 1200px) {
  .hide-on-mobile {
  display: none;
  }
}

.sign-in-link {
  margin-left: -10px !important;
}

.secondary-button {
  border: 1px solid #a546fc !important;
  color: #a546fc !important;
  border-radius: 12px;
  padding: 10px 20px !important;
  transition: all 0.3s;
}

.tertiary-button {
  color: #a546fc !important;
  background: none !important;
  border: 1px solid #a546fc !important;
}

.tertiary-button:hover {
  border: 1px solid white !important;
  color: white !important;
  cursor: pointer;
}

.fourth-button {
  color: var(--accent-color) !important;
  background: #181818 !important;
  border: 1px none #a546fc !important;
  font-size: 16px;
  padding: 10px 20px;
  transition: all 0.3s;
}

.fourth-button:hover {
  border: 1px none white !important;
  cursor: pointer;
  background: #242424 !important;
}

.secondary-button:hover {
  border: 1px solid white !important;
  color: white !important;
}

.previous-page-button {
  display: block;
  font-weight: bold;
  text-align: left;
  padding: 10px 0px;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  background: none;
  border: none;
  font-size: 16px;
  margin-left: 0px;
}

.previous-page-button:hover {
  transition: all 0.3s; 
  color: var(--accent-color);
  background: none;
}

.previous-page-icon {
  margin-right: 10px;
}

.new-page-button {
  display: block;
  font-weight: bold;
  text-align: left;
  padding: 10px 0px;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  background: none;
  border: none;
  font-size: 16px;
  margin-left: 0px;
}

.new-page-button:hover {
  transition: all 0.3s; 
  color: var(--accent-color);
  background: none;
  cursor:pointer;
}

.new-page-icon {
  color:  var(--accent-color);
  margin-left: 10px;
}

.primary-header-button{
  display: block;
  width: 230px;
  padding: 15px 20px;
  background-color: #a546fc;
  color: #fff;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 20px;
  margin-left: 0px;
  transition: all 0.5s;
  text-align: center;
}

.primary-header-button:hover {
  background-color: white;
  color: #a546fc;
}

@media (max-width: 1024px) {
  .primary-header-button{
  margin: auto;
  }

  .center-on-mobile {
    justify-content: center;
    text-align: center !important;
  }
}

.text-center{
  text-align: center;
}

.text-align-center {
  text-align: center !important;
}

.text-button {
  color: #a546fc;
  margin-top: 20px;
}

.gray-text {
  color: #a9a9a9;
}

.purple-text{
  color: #a546fc !important;
  text-align: center;
}

.purple-dot {
  position: absolute;
  right: 10px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--accent-color);
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 3px;
}

.red-text {
  color: rgb(175, 60, 60) !important;
}

.left-align {
  text-align: left;
}

.text-align-left {
  text-align: left;
}

.white-text {
  color: white !important;
}

.italic {
  font-style: italic;
}

.small-font{
  font-size:14px;
}

.link {
  color: #a546fc !important;
}

.link:hover {
  color: white !important;
}

.header-title-content {
  text-align: center;
  margin: 60px auto;
  max-width: 700px;
}

.alert-container {
  position: relative; /* This makes it the positioning context */
  width: 90%; /* Full width */
  margin: auto;
  display: flex;
  justify-content: center; /* Centers the child horizontally */
  align-items: center; /* Centers the child vertically */
}

.alert {
  position: relative;
  padding: 0px 15px;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: fit-content;
  text-align: center;
}

.alert-info {
  background-color: #121212;
  color: white;
  border: 0.25px solid #191919;
}

.alert-icon {
  background-color: var(--accent-color+"");
  margin-right: 10px; /* Space between icon and text */
  font-size: 20px; /* Adjust icon size as needed */
  border: 1px solid var(--accent-color); /* Circle border color */
  border-radius: 50%; /* Makes the border rounded */
  padding: 5px; /* Adjust padding as needed */
  display: inline-flex; /* Aligns the icon center */
  align-items: center; /* Centers the icon vertically */
  justify-content: center; /* Centers the icon horizontally */
  width: 24px; /* Fixed width */
  height: 24px; /* Fixed height, making the element a perfect circle */
  font-size: 18px;
  color: var(--accent-color);
}

.permissions-field {
  margin-bottom: 20px;
}

.alert button {
  display: block;
  background: none;
  border: none;
  color: inherit; /* Button text inherits the alert's text color */
  text-align: right;
  width: 100%;
  cursor: pointer;
}

.alert button:hover {
  text-decoration: underline; /* Simple hover effect for the button */
}

/* Organizer Marketing Page */

.email-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
}

.email-item p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.email-item button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.email-item button:hover {
  background-color: #c0392b;
}


.email-preview-box {
  color: black;
}

/* Select Plan Page */

.upgrade-notice {
  background-color: #0f0b10;
  padding: 10px 30px;
  border-radius: 12px;
  border: solid 0.25px #191919;
  color: white;
  box-shadow: 0 0 30px rgba(183, 0, 255, 0.2);
}

.upgrade-notice {
  background-color: #0f0b10;
  padding: 10px 30px;
  border-radius: 12px;
  border: solid 0.25px #191919;
  color: white;
  box-shadow: 0 0 30px rgba(183, 0, 255, 0.2);
}

.upgrade-notice p{
  color: white;
}

.upgrade-notice button {
  background-color: white;
  color: #0f0b10;
  margin-bottom: 20px;
}

.upgrade-notice button:hover {
  background-color: white;
  color: #a546fc;
  margin-bottom: 20px;
}

.plan-price h3{
  font-size: 36px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 0px;
  text-wrap: nowrap;
}

.plan-price h3{
  color: white !important;
}

.plan-price p{
  margin-top: 0px;
  font-size: 12px;
}

.margin-auto {
  margin: auto;
}

.confirmation-section {
  display: flex;
  flex-direction: row;
  background-color: #a546fc; /* Light background color for contrast */
  padding: 20px; /* Padding for spacing inside the section */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for a lifted effect */
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0px;
  align-items: center;
}

.confirmation-text {
  text-align: left;
}

.confirmation-buttons {
  position: absolute;
  right: 20px;
}

.confirmation-section h3 {
  font-size: 18px; /* Larger font size for the heading */
  margin: 0px; /* Margin below the heading */
}

.confirmation-section p {
  font-size: 16px; /* Medium font size for the paragraph */
  margin-bottom: 0px; /* Margin below the paragraph */
  color: white;
  margin-top: 5px;
}

.confirmation-section .cta-button {
  background-color: white; /* Primary button color */
  color: #a546fc; /* White text color */
  padding: 10px 20px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners for the button */
  cursor: pointer; /* Pointer cursor on hover */
  margin: 0 10px; /* Margin between buttons */
  font-size: 16px; /* Font size for button text */
  transition: all 0.3s ease; /* Smooth transition for background color */
}

.confirmation-section .cta-button:hover {
  box-shadow: 0 0px 10px rgba(255, 255, 255, 0.9); /* Light shadow for a lifted effect */
  background: white;
}

.confirmation-section .cancel {
  color: white;
  background: none;
}

.confirmation-section .cta-button:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Add custom focus outline */
}


.plan-header {
  display: flex;
  flex-direction: row;
  background-color: #121212;
  align-items: center;
  justify-content: center;
}

.plan-header h3{
text-align: center;
}

.back-to-dash {
  position: absolute;
  left: 20px;
}

.pricing-plan.current-plan {
  border: 1px solid gray; /* Blue border for current plan */
}

.pricing-plan.selected {
  border-color: #a546fc;
  box-shadow: 0 0 15px rgba(183, 0, 255, 0.9);
  transition: all 0.3s;
}

.pricing-plan.selected {
  background-color: #0f0b10;
}

/* Not Found Page */

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: var(--background-2-color);
  padding: 20px;
  box-sizing: border-box;
}

.not-found-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.not-found-page h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
}

.not-found-page p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #666;
}

.not-found-page a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #a546fc;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-page a:hover {
  background-color: white;
  color: #a546fc;
}

@media (max-width: 600px) {
  .not-found-page h1 {
    font-size: 2rem;
  }

  .not-found-page p {
    font-size: 1rem;
  }

  .not-found-logo {
    width: 120px;
  }
}

/* Circle Page */

@media screen and (max-width: 1024px) {
  .circle-group-view {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
  }

  .circle-information {
    width: 100% !important;
    overflow-y:visible;
    padding-right: 0px;
    max-height: 85vh;
  }

  .circle-conversation{
    width: 100%;
    position: relative;
    right: 0px;
    overflow-y: hidden;
    height: 85vh;
  }
}

.member-roles {
  display: block;
  font-size: 12px;
  color: #888; /* Adjust the color as needed */
  margin: 0px 10px;
}


.user-search-results {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  height: 100%;
}

.user-search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;  /* Content is aligned to the left */
  border-bottom: 0.25px solid #2d2d2d;
  padding: 12px 12px;
  border-radius: 0px;
  transition: all 0.5s;
  width: 100%; /* Make the li element take the full available width */
}

.user-search-item:hover {
background-color: #242424;
border-radius: 12px;
border-bottom: none;
}

.member-actions {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

.member-actions .fourth-button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4CAF50; /* Default button background color */
  color: white; /* Default button text color */
  transition: background-color 0.3s ease;
}

.member-actions .fourth-button:hover {
  background-color: #45a049; /* Hover state for the button */
}

.member-actions .fourth-button.red-text {
  background-color: #f44336; /* Red background for remove button */
}

.member-actions .fourth-button.red-text:hover {
  background-color: #e53935; /* Hover state for the remove button */
}


.admin-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  text-align: left !important;
}

.circle-group-view {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.circle-information {
  width: 40%;
}

/* Circles and Messaging */

.unread-dot {
  height: 10px;
  width: 10px;
  background-color: var(--accent-color);
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
}

.conversation-item h4 {
  font-weight: normal;
}

.conversation-item .conversation-last-message {
  font-weight: normal;
}

.selected-vertical-tab h4,
.selected-vertical-tab .conversation-last-message {
  font-weight: bold;
}


.sender-info {
  display: flex;
  align-items: center;
  justify-content: left;
  color: white;
  font-size: 12px;
  margin-left: 35px;
}

.circle-pp-text {
  display: flex;
  align-items: end;
}

.deet-message {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  height: 150px;
  border-radius: 12px;
  overflow: hidden;
  align-self: flex-start;
}

.message-textarea {
  height: 40px !important;
  min-height: 0px !important;
  border-radius: 24px 24px;
  max-height: 200px !important;
}

.send-message-button {
  height: 40px;
}

.deet-message p {
margin-top: 0px !important;
}

.deet-message:hover {
  border-color:  var(--accent-color);
  box-shadow: 0 0 10px rgba(183, 0, 255, 0.5);
}

.deet-message.outgoing {
  align-self: flex-end;
  margin-left: auto; /* Align to the right */
}

.deet-message.incoming {
  margin-right: auto; /* Align to the left */
}

.deet-message img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.deet-message .gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, transparent, black);
  z-index: 1;
}

.deet-message .text-content {
  position: absolute;
  bottom: 10px;
  left: 15px;
  z-index: 2;
  color: white;
}

.deet-message .text-content h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.deet-message .text-content p {
  margin: 5px 0;
  font-size: 12px;
}

.deet-message .attendees-going {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  color: white;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  text-shadow: 0px 0px 5px black
}

.deet-message .attendees i {
  margin-left: px;
}

.deet-message .connections {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  color: white;
  font-size: 0.9em;
}



.search-results {
  margin-top: 20px;
  overflow: auto;
}

.message-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.message-modal-content {
  background: #121212;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  height: 600px;
  max-width: 90%;
  z-index: 9999;
  overflow: auto;
}


.circles-messages {
  display: flex;
  height: 100vh;
  width: 100%;
}

.conversation-list {
  width: 50%;
  overflow-y: auto;
  background-color: black;
  padding-right: 40px;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #242424;
  border-radius: 12px;
}

.conversation-item:hover {
  background-color: #242424;
  border-radius: 12px;
}

.conversation-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.conversation-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.conversation-info h4 {
  margin: 0;
  font-size: 16px;
}

.conversation-timestamp {
  font-size: 12px !important;
  color: #888;
  white-space: nowrap; /* Prevents wrapping */
  margin-left: 10px; /* Adds a little space between the message and the timestamp */
  text-align: right;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversation-info p {
  margin: 5px 0 0;
  font-size: 16px;
  color: gray;
}

.conversation-view {
  position: fixed;
  right: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  background-color: #242424;
  border-radius: 24px;
  height: 85vh;
  max-height: 85vh;
  z-index: 0;
}

.conversation-header {
  height: 85px;
  text-align: center;
  padding: 10px;
  border-bottom: 0.25px solid #404040;
  width: 100%;
}

.no-conversation-selected {
  margin: auto;
  text-align: center;
  color: #888;
}

.direct-conversation {
  width: 100%;
  position: sticky;
  height: 100%;
  border-radius: 24px;
}

.messages-list {
  width: 100%;
  flex: 1;
  padding: 20px;
  height: calc(85vh - 160px);
  overflow-y: auto;
  background-color: #242424;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.message-item {
  max-width: 60%;
  margin: 5px 0;
  border-radius: 20px;
  display: inline-block;
  clear: both;
}

.message-deets {
  height: 100%;
  border-radius: 20px !important;
}

.message-text {
  padding: 10px 20px;
  border-bottom-left-radius: 0 !important;
}

.message-text.incoming {
  background-color: #f1f1f1;
  border-radius: 20px;
  border-bottom-left-radius: 0 !important;
}

.message-item.outgoing {
  background-color:  var(--accent-color);
  color: #fff;
  align-self: flex-end;
  justify-content: flex-end;
  margin-left: auto; /* Align to the right */
  border-bottom-right-radius: 0;
}

.message-item.incoming {
  background-color: #f1f1f1;
  color: #333;
  align-self: flex-start;
  justify-content: flex-start;
  margin-right: auto; /* Align to the left */
}

.message-deets.incoming {
  border-bottom-left-radius: 12px !important;
}

/* For Circles */

.circle-message-item {
  max-width: 60%;
  margin: 5px 0;
  border-radius: 20px;
  display: inline-block;
  clear: both;
}

.circle-message-item.outgoing {
  color: #fff;
  align-self: flex-end;
  justify-content: flex-end;
  margin-left: auto; /* Align to the right */
  border-bottom-right-radius: 0;
}

.circle-message-item.incoming {
  color: #333;
  align-self: flex-start;
  justify-content: flex-start;
  margin-right: auto; /* Align to the left */
  border-bottom-left-radius: 0;
}

.circle-message-deets {
  margin: 0px 0;
  border-radius: 20px;
  display: inline-block;
  clear: both;
}

.circle-message-deets.outgoing {
  color: #fff;
  align-self: flex-end;
  justify-content: flex-end;
  margin-left: auto; /* Align to the right */
  border-bottom-right-radius: 0;
}

.circle-message-deets.incoming {
  color: #333;
  align-self: flex-start;
  justify-content: flex-start;
  margin-right: auto; /* Align to the left */
  border-bottom-left-radius: 0;
}

.circle-message-text {
  margin: 5px 0;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  clear: both;
}

.circle-message-text.outgoing {
  color: #fff;
  background-color:  var(--accent-color);
  align-self: flex-end;
  justify-content: flex-end;
  margin-left: auto; /* Align to the right */
  border-bottom-right-radius: 0;
}

.circle-message-text.incoming {
  color: #333;
  background-color: #f1f1f1;
  align-self: flex-start;
  justify-content: flex-start;
  margin-right: auto; /* Align to the left */
  border-bottom-left-radius: 0;
}

.direct-conversation {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
}

.message-input {
  width: 100%;
  position: relative;
  bottom: 0px;
  display: flex;
  padding: 10px;
  background-color: #121212;
  border-radius: 0px 0px 24px 24px;
  align-items: center;
  z-index: 99;
}

.message-input input {
  flex: 1;
  padding: 10px 20px !important;
  border-radius: 50px !important;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.message-input button {
  padding: 10px 20px;
  border: none;
  background-color:  var(--accent-color);
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
}

.message-timestamp.centered {
  text-align: center;
  font-size: 0.9em;
  color: #999;
  margin: 10px 0;
}

.message-content {
  position: relative;
}

.message-time {
  position: absolute;
  display: none;
  font-size: 12px;
  color: #999;
  background-color: #121212;
  bottom: -25px;
  width: 120px;
  border-radius: 9px;
  z-index: 99;
  padding: 5px 10px;
  text-align: center;
}

.circle-message-item:hover .message-time {
  display: block;
}

.circle-message-item.incoming:hover .message-time {
  display: block;
  left: 20px;
}

.circle-message-item.outgoing:hover .message-time {
  display: block;
  right: 20px;
}

.message-item:hover .message-time {
  display: block;
}

.message-item.incoming:hover .message-time {
  display: block;
  left: 20px;
}

.message-item.outgoing:hover .message-time {
  display: block;
  right: 20px;
}


.message-status {
  font-size: 12px;          /* Smaller font size for the status text */
  color: #888888;           /* Light gray color for visibility */
  margin-top: 4px;          /* Space above the status text */
  text-align: right;        /* Align the status text to the right */
  padding-right: 10px;      /* Padding to match the text alignment */
}

.message-item.outgoing .message-status {
  text-align: left;         /* Align status text to the left for outgoing messages */
  padding-left: 10px;       /* Padding to match the text alignment */
}

.message-item.incoming .message-status {
  text-align: right;        /* Align status text to the right for incoming messages */
  padding-right: 10px;      /* Padding to match the text alignment */
}

@media screen and (max-width: 1250px) {

  .conversation-list {
    margin-left: -40px !important;
  }

  .circle-information {
    margin-left: -40px !important;
  }
}

@media screen and (max-width: 1024px) {
  .circles-messages {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    max-height: 85vh;
    overflow-y: hidden;
  }

  .conversation-info {
    width:50px;
  }

  .conversation-list {
    width: 100%;
    overflow-y:visible;
    padding-right: 0px;
    margin-left: 0px !important;
  }

  .circle-information {
    margin-left: 0px !important;
  }

  .conversation-view{
    width: 100%;
    position: relative;
    right: 0px;
    max-height: 100vh;
    overflow-y: hidden;
  }

  .conversation-view .new-page-button{
    position: absolute;
    left: 20px;
    z-index: 99
  }
}


/* Get Started ------------------------------------------------------------------- */

.checkbox-container {
  position: relative;
  margin: 20px 0;
  align-items: top;
}

.checkbox-label {
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #333;
  cursor: pointer; /* Ensures cursor indicates clickability */
  height: 100px;
  align-items: top;
}

.checkbox-input {
  position: absolute;
  top: 0px;
  appearance: none; /* Remove default browser styling */
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-right: 15px;
  border: 1px solid gray;
  border-radius: 4px; /* Rounded corners for a modern look */
  cursor: pointer;
  position: relative;
  outline: none;
  padding: 0px;
}

.checkbox-input:hover {
  border: 1px solid #a546fc;
}

.checkbox-input:checked {
  border-color: #a546fc; /* Border color when checked */
  box-shadow: 0 0 0 30px black inset;
}

.checkbox-input:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 5px;
  border: solid #fff;
  border-width: 0 0 2px 2px;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.checkbox-input:focus {
  box-shadow: 0 0 0 30px black inset;
  transition: all 1s;
}

.checkbox-text {
  position: absolute;
  top: 1px;
  color: white;
}

.pricing-plans-2 {
  display: grid; /* New - This will make the post items align in a grid */
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  margin-bottom: 40px;
  flex: wrap;
  width: 100%;
}

.pricing-plan-2 {
  border: 1px solid #212121;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #151515;
  margin-top: 60px;
  position: relative; /* For the badge positioning */
  cursor: pointer;
  margin: 10px;
  transition: all 0.3s;
}

.get-started-page .pricing-plan-2:hover,
.get-started-page .pricing-plan-2.selected {
  border-color: #a546fc;
  box-shadow: 0 0 10px rgba(183, 0, 255, 0.5);
  transition: all 0.3s;
}

.get-started-page .pricing-plan-2.selected {
background-color: #0f0b10;
}

.plan-badge {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.features {
  list-style-type: none;
  padding: 0;
}

.features li {
  margin: 5px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  background: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin: 40px 0px;
  margin-right: 10px;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  top: -2px;
  left: 4px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #a546fc;
  border-color: #a546fc;
}

input[type="checkbox"]:checked + .custom-checkbox::after {
  display: block;
}

.checkbox-label {
  flex-grow: 1;
  font-size: 16px !important;
}


.get-started-page {
  display: flex;
}

.progress-sidebar {
  width: 20%;
  min-height:100vh;
  height:100%;
  padding: 40px;
  border-right: 1px solid #242424;
  background-image: url(./background.png);
  background-size: cover;
  position: fixed;
  z-index: 9999;
}

.progress-step {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  padding-left: 20px;
}

.progress-step.active {
  color: #a546fc;
  font-weight: bold;
  border-left: 2px solid #a546fc;
  border-radius: 4px;
}

.get-started-container {
  position: relative;
  height: 100%; 
  width: 80%;
  min-height: 100vh;
  background-color: #121212;
  overflow: hidden;
  margin: 0px 0px 0px auto;
}

.get-started-content {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  min-width: auto;
  width: 70%;
  justify-content: space-between;
  margin: 80px;
}

.get-started-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.get-started-form h1 {
  color: white;
  margin-bottom: 20px;
  margin-top: 0px;
}

.get-started-form p {
  color: #a9a9a9;
}

.get-started-form input[type="text"],
.get-started-form input[type="email"],
.get-started-form input[type="tel"],
.get-started-form input[type="number"],
.get-started-form select,
.get-started-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  margin: 0px;
  margin-bottom: 16px;
  font-size: 16px;
  border: 1px solid #242424;
}

.get-started-form select {
  background-color: var(--input-background);
  color: white;
  border: 1px solid #242424;
}

.get-started-form label {
  margin: 5px;
  font-size: 14px;
}

.input-error {
  border: 1px solid darkred;
}

.label-error {
  color: darkred;
}


.form-buttons {
  display: flex;
  flex-direction: row;
  width: 300px;
  margin-bottom: 60px;
}

.get-started-form input[type="file"] {
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.form-button {
  width: calc(50% - 4px);
  padding: 10px 0;
  margin: 5px;
  background-color: #a546fc;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: white;
  color: #a546fc;
}

.form-back-button {
  background-color: #121212;
  border: 1px solid #a546fc;
  color: #a546fc;
}

.form-back-button:hover {
  background-color: #a446fc5c;
}

@media (max-width: 768px) {
  .pricing-plans-2 {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1424px) {
  .pricing-plans-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1024px) {

  .get-started-page {
    flex-direction: column;
    align-items: center;
  }

  .progress-sidebar {
    display: none;
  }

  .get-started-content {
    padding: 10px;
    width: 90%;
    margin: 90px auto;
    min-width: auto;
  }

  .get-started-container {
    width: 100%;
    overflow: wrap;
  }

  .get-started-form {
    width: 100%;
  }
}

.section-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.highlight-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.highlight-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}

.highlight-image {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.highlight-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.highlight-description {
  font-size: 14px;
}


/* Guides Page ------------------------------------------------------------------- */

.guides-container {
  width: 50%;
  margin: 150px auto;
}

.guides-list {
  padding: 0;
  margin-top: 20px;
}

.guide-item {
  background-color: #121212;
  border: 1px solid #242424;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.guide-item h2 {

}

.guide-item p {
  color: #a9a9a9;
}

.guide-item a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #a546fc;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.4s;
}

.guide-item a:hover {
  background-color: white;
  color: #a546fc;
}


/* FAQs Page ------------------------------------------------------------------- */

.faq-container {
  width: 80%;
  margin: 20px auto;
  margin-top: 190px;
}

.faq-list {
  list-style: none;
  padding: 0;
}

.faq-item {
  margin-bottom: 10px;
  border: 1px solid #121212;
  border-radius: 5px;
  overflow: hidden; /* Ensures content animates smoothly */
}

.faq-question {
  font-weight: normal;
  padding: 10px 15px;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  background-color: #121212;
  border-bottom: 1px solid #242424;
  transition: all 0.3s ease-out; /* Smooth background color transition */
}

.faq-question:hover {
  color: #a546fc; /* Slightly darker on hover */
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  padding: 0 15px; /* Starts with no vertical padding */
}

.faq-item.active .faq-answer {
  max-height: 1000px; /* Arbitrary large height to accommodate content */
  padding: 10px 15px; /* Adds padding when visible */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}



/* Contact Us Page ------------------------------------------------------------------- */

/* General page styles */
.contact-us-page {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
}

.contact-form-container {
  max-width: 800px;
  margin: 130px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-form-container h1 {
  text-align: center;
}

.contact-form-container p {
  text-align: center;
}

/* Form styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical; /* Allow only vertical resize */
}

.contact-form button {
  padding: 10px 20px;
  background-color: #a546fc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #ffffff;
  color: #a546fc;
}

/* Success and error messages */
.success-message,
.error-message {
  color: #fff;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  transition: all 0.5s;
}


/* Feature Pages --------------------------------------------------------------------*/

/* Animations ------------------------------------------------------------------------ */

@keyframes fade {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 0.8s ease-out;
}

.header .text-section .homepage-title,
.header {
  animation: fadeUp 0.8s ease-out forwards;
}

.image-section {
  animation-delay: 0.8s;
  animation: fadeUp 2s ease-out forwards;
}

.header .text-section .homepage-title {
  animation-delay: 0.5s;  /* Delays the start of the animation */
}

.header .image-section {
  animation-delay: 0.8s;  /* Slightly later to create a sequence */
}

.header .text-section .homepage-title,
.header .image-section {
  opacity: 0;  /* Ensure elements are invisible until the animation starts */
}

/* Info ----------------------------------------------------------------------------*/


.info-section {
  width: 80%;
  margin: 100px auto;  /* Reduced margin for all sizes, adjust as needed */
}

.deets-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch; /* Ensures all .info elements have the same height */
}

@media (max-width:1024px) {
  .PostDeetsModal .input-container {
    display: flex;
    flex-direction: column !important;
  }
}


.info {
  flex: 1;  /* Allows each item to grow and take up available space */
  padding: 20px;
  min-width: 200px;  /* Ensures that the .info blocks do not become too small */
  box-sizing: border-box;  /* Includes padding and border in the element's total width and height */
  text-align: left;  /* Centers the text for better mobile viewing */
}

.info-icon {
  font-size: 36px;
  color: #a546fc; /* Purple color */
  transition: all 1s ease-out;
}

.info-number {
  margin: 20px 0;
  font-weight: bold;
  width: 100%;
}

.info-label {
  width: 100%;
  font-size: 16px;
  color: #a9a9a9;
  margin: 0px;
}

@media (max-width: 1024px) {
  .info-section {
    width: 80%;
    margin-top: 20px;
  }

  .info {
    margin-bottom: 30px;
  }
}

/* Testimonial */

.prev-slide, .next-slide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 9999;
  font-size: 24px;
}

.prev-slide {
  left: 20px;
}

.next-slide {
  right: 20px;
}

.testimonials-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #121212;
  width: 100%;
  position: relative;
}

.testimonials-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.testimonial-slide {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.slide {
  display: none;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  display: block;
  transition: opacity 1s ease-in-out;
}

.testimonial-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.testimonial-info {
  text-align: left;
}

.testimonial-info p {
  margin: 0px;
}

.testimonial-info h3 {
  margin: 0px;
}

.testimonial-info img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.testimonial-info h3 {
  font-size: 1.2em;
  font-weight: bold;
}


/* Event Section --------------------------------------------------------------------- */

.event-section {
  width: 80%;
  margin: auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.row.reverse {
  /*flex-direction: row-reverse; */
}

.column {
  flex: 1;
}

.column.image {
  padding: 15px;
  transition: all 1s;
  animation-delay: 5s;
}

.column.content {
  padding: 60px;
  text-align: left;
}

.column.content p {
  color:#a9a9a9;
}

.event-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 30px #a446fc36;
  border: solid 1px rgb(61, 61, 61);
}

@media (max-width: 1024px) {

  .event-section {
    width: 90%;
  }

  .row {
    flex-direction: column; /* Horizontal layout for tablets and up to smaller desktops */
    justify-content: space-between; /* Space out columns */
    text-align: left; /* Align text to left for readability */
  }

  .row.reverse {
    flex-direction: column-reverse; /* Reverse row order for the second row */
  }

  .column.image, .column.content {
    flex: 1; /* Allow both columns to share space evenly */
    padding: 15px; /* Adequate padding around content */
  }

  .column.content {

  }
}

/* Financial Impact Section */

.neon-border {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  padding: 25px 100px;
  border: 5px solid white;
  box-shadow: 0 0 8px rgba(234, 76, 137, 0.75), 0 0 20px rgba(77, 9, 121, 0.75), inset 0 0 10px rgba(255, 255, 255, 0.3);
  animation: neon 1.5s ease-in-out infinite alternate;
  width: 900px;
}

@keyframes neon {
  from {
      box-shadow: 0 0 10px #a546fc, 0 0 20px #4d0979, inset 0 0 10px #a546fc;
  }
  to {
      box-shadow: 0 0 20px #a546fc, 0 0 30px #4d0979, inset 0 0 15px #a546fc;
  }
}

.financial-svg {
  width: 100%; /* Full width to stretch across the container */
  height: 100px; /* Fixed height for the SVG */
  position: absolute; /* Position absolutely within the financial-impact-section */
  bottom: 0; /* Align to the bottom of the container */
  left: 0; /* Align to the left of the container */
}

.financial-svg path {
  stroke: #6c63ff; /* Purple color for the path */
  stroke-width: 2; /* Thickness of the path */
  stroke-dasharray: 5, 5; /* Dashed style with equal dash and gap lengths */
}

.financial-impact-section {
  text-align: center; /* Center everything inside the section */
  padding: 50px 20px; /* Add some padding around the section */
  color: #333; /* Dark text color for readability */
  position: relative; /* Establish positioning context for absolute elements */
  overflow: hidden; /* Ensures nothing overflows */
  background-image: url(./background.png);
  background-size: cover;
  background-position: center;
  width: 100%;
}

.financial-number {
  font-size: 20px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.dollar-icon {
  font-size: 30px;
  margin-right: 5px;
}

.financial-counter {
  font-weight: bold;
  font-size: 10vw;
}

.impact-detail {
  max-width: 620px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin: 20px auto;
}


/* Feature Highlights------------------------------------------------------------ */


.tab-selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Ensures even spacing between elements */
  margin: 30px auto;
  margin-top: 50px;
  width: 100%;
  max-width: 600px;
  background-color: #101010;
  border-radius: 48px;
  box-shadow: 0 4px 30px #a446fc1b;
  border: solid 1px rgb(61, 61, 61);
  padding: 10px 10px 12px 10px;
}

.admin-tools-section .tab-selector button {
  flex-grow: 1; /* Allows buttons to grow and fill the parent container evenly */
  text-align: center;
  padding: 10px 20px;
  background: none;
  cursor: pointer;
  border: none; /* Removes individual button borders to ensure uniform appearance */
  transition: background-color 0.3s;
}

.admin-tools-section .tab-selector button.active {
  color: white;
  background-color: #a546fc;
  transition: all 0.5s; /* Ensures smooth transition for color and background changes */
  border-radius: 24px;
  box-shadow: 0 4px 30px #000000;
}

.tab-selector button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  padding: 10px 20px;
  transition: color 0.3s;
  border-radius: 24px;
}

.tab-selector button.active {
  font-weight: bold;
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: #a546fc;
  transition: left 0.5s ease-out, width 0.5s ease-out;
  border-radius: 24px;
}

.admin-tools-section {
  display: flex;
  flex-direction: column;
  margin: 90px auto;
  align-items: center;
  max-width: 80%;
}

.section-title {
  text-align: center;
  margin-bottom: 20px;
}

.new-section {
  max-width: 90vw;
  text-wrap: wrap;
  text-align: center;
  margin: auto;
}

.highlight-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding: 20px;
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.highlight-item {
  background: #121212;
  border-radius: 12px;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;
  box-shadow: 0 4px 30px #a446fc36;
  border: solid 1px rgb(61, 61, 61);
  text-align: left;
  transition: all 0.3s;
}

.highlight-item img{
  transition: all 0.5s;
}

.highlight-item:hover img{
box-shadow: 0 4px 30px #a446fc36;
}

.highlight-image {
  width: 100%;
  border-radius:9px;
  border: solid 0.5px rgb(61, 61, 61);
}

.highlight-content {
  padding: 20px;
}

.highlight-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.highlight-description {
  font-size: 14px;
  color: #a9a9a9;
}

@media (max-width: 1024px) {
  .admin-tools-section {
    display: flex;
    flex-direction: column;
    margin: 90px auto;
    align-items: center;
    max-width: 95%;
  }
}

/* Timeline ------------------------------------------------------------------------- */

.milestone {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #121212;
  border-radius: 50%;
  border: 3px solid #a546fc; /* Purple border */
  z-index: 999;
}

.active-milestone {
  margin-left: -10px;
  animation: pulse 2s infinite;
  z-index: 999;
}

.active-milestone::after {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: radial-gradient(circle, #a546fc 0%, rgba(165, 70, 252, 0) 70%);
  animation: pulseAnimation 1s ease-out forwards;
}

@keyframes pulseAnimation {
  0% { transform: scale(0); opacity: 1; }
  100% { transform: scale(4); opacity: 0; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

/* Animations for timeline sections when milestones are reached */


.timeline-image {
  background-attachment: fixed;
}

/* Animation for timeline sections entering from the left */
.timeline-section:nth-child(odd).active {
  animation: slideInFromLeft 0.8s forwards; /* Only animate in when becoming active */
}

/* Animation for timeline sections entering from the right */
.timeline-section:nth-child(even).active {
  animation: slideInFromRight 0.8s forwards; /* Only animate in when becoming active */
}

@keyframes slideInFromLeft {
  from {
      transform: translateX(-100px); /* Start from further left */
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
      transform: translateX(-100%); /* Start from further right */
      opacity: 0;
  }
  to {
      transform: translateX(-120%);
      opacity: 1;
  }
}

.timeline-container {
  position: relative;
  width: 2px; /* Line width */
  height: 1330px; /* Total height of the timeline */
  background-color: #ccc; /* Gray line color */
  margin: 100px auto; /* Center the timeline */
  margin-bottom: 100px;
}

.timeline-line {
  position: absolute;
  top: 0; /* Start growing from the bottom */
  width: 100%;
  background: linear-gradient(to bottom, #a546fc 70%, #e1c1ff 80%, #a546fc 90%); /* Gradient from purple to a darker purple */
  transition: all ease-out;
}


.timeline-dot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #a546fc;
  transition: top ease;
}

.timeline-section {
  min-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  color: #666; /* Text color */
  transition: all 0.5s ease-out;
  margin: 0px 50px;
  opacity: 0; /* Start invisible */
  transform: translateX(-50px); /* Start shifted left */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smooth transitions for fade and slide */
}

.timeline-section:nth-child(even) {
  flex-direction: row-reverse;
}

.timeline-text {
text-wrap:wrap;
padding: 40px;
}

.timeline-image-fit {
  width: 200px;
  height: 400px;
  object-fit: cover;
  border-radius: 11px;
}

.timeline-section h2{
  color: white;
}

.timeline-section p{
  color: #a9a9a9;
}

.descriptor{
  color: greenyellow !important;
}

@media (max-width: 1024px) {

    /* Animation for timeline sections entering from the right */
  .timeline-section:nth-child(even).active {
    animation: slideInFromLeft 0.8s forwards; /* Only animate in when becoming active */
  }
  .timeline-section:nth-child(even) {
    flex-direction: column;
  }

  .timeline-section {
    flex-direction: column;
    margin: 10px;
  }

  .timeline-image-fit {
    width: 75vw;
    height: 200px;
    object-fit: cover;
    border-radius: 11px;
  }

  .timeline-section h2{
    margin-top: 0px;
  }

  .timeline-text {
    padding: 0px;
    max-width: 280px;
  }

  .timeline-container {
    position: relative;
    left: -45%;
    width: 2px; /* Line width */
    height: 1200px; /* Total height of the timeline */
    background-color: #ccc; /* Gray line color */
    margin: 100px auto; /* Center the timeline */
    margin-bottom: 300px;
  }

  .timeline-line {
    position: absolute;
    top: 0; /* Start growing from the bottom */
    width: 100%;
    background-color: #a546fc; /* Purple line color */
    transition: all ease-out;
  }

  .timeline-dot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #a546fc;
    transition: top ease-out;
  }

}

/* Stats Section ---------------------------------------------------------------------------- */

.center-link {
  text-align: center !important;
  margin: auto;
  align-self: center;
}

.stats-section {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 50px 0;
  margin: 30px;
  margin-top: 0px;
}

.deets-description {
  max-width: 500px;
}

.deets-stats {
  display:flex;
  flex-direction: row;
}

.stats-section .stats-divider {
  border: #121212 solid 1px;
  height: 200px;
  color: #121212;
  background-color: #121212; /* Light gray color */
  margin: 30px;
}


.stat {
  padding: 20px;
}

.stat-icon {
  font-size: 42px;
  color: #a546fc; /* Purple color */
  transition: all 1s ease-out;
}

.stat-number {
  font-size: 48px;
  margin: 10px 0;
  font-weight: bold;
}

.stat-label {
  font-size: 16px;
  color: #a9a9a9;
  margin: 0px;
}

@media (max-width: 1024px) {
  .stats-section {
    width: 70%;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    color: white;
    padding: 50px 0;
    margin: auto;
  }
  
  .deets-description {
    max-width: 500px;
    margin: auto;
  }
  
  .deets-stats {
    flex-direction: column;
    justify-content:flex-start;
    align-items: top;
    width: 100%;
  }

  .stat {
    padding: 30px 0px;
  }
  
  .stats-section .stats-divider {
display: none;
  }
}

/* Header------------------------------------------------------------------------------------- */

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  background-color: #121212;
  padding-top: 120px;
  margin-bottom: 60px;
}

.solution-content {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  align-items: center; /* Center children vertically */
  justify-content: space-between; /* Space out children */
  height: 600px;
  text-align: left;
  width: 80%; /* Adjust as needed */
}

.text-section {
  width: 50%; /* Adjust based on your content and image size */
}

.image-section {
  width: 50%; /* Adjust based on your content and image size */
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}

.image-section img {
  width: 100%;
  max-width: 1000px; /* Ensures the image is responsive and doesn't overflow */
  height: 600px;
  object-fit: contain;
}

.tool-image img{
  max-width: none;
  width: 140%;
}

.tool-section {
  max-width: 500px !important;
  width: 32%;
}

@media (max-width: 1024px) {
  .header {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .solution-content {
    flex-direction: column-reverse;  /* Stack vertically on small screens */
    text-align: center;  /* Center text for smaller screens */
    margin-bottom: 40px;
    justify-content:center;
    height: fit-content;
    min-height: 650px;
    margin: auto;
    width: 85%;
    margin-top: 30px;
  }

  .tool-content{
    margin: 90px;
  }

  .text-section {
    padding-right: 0;  /* Remove right padding on small screens */
    width: 100%;
  }

  .image-section{
    width: 100%;
    margin: 0px;
    margin-bottom: 0px;
  }
  
  .image-section img {
    max-width: 100vw; /* Ensures the image is responsive and doesn't overflow */
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .tool-image img{
    max-width: none;
    width: 95vw;
  }

  .header h1 {
    font-size: 24px;  /* Smaller font size for the title */
  }

  .header h2 {
    font-size: 18px;  /* Smaller font size for the description */
  }

  .header-buttons {
    justify-content: center;  /* Center buttons on small screens */
  }
}


/* Top Navigation Bar---------------------------------------------------------------------- */

.menu-section {
  /* Styling for each section (Features, Tools, etc.) */
}

.tools-list {
  /* Similar styling as .features-list */
}

.tool-item {
  /* Similar styling as .feature-item */
}

.features-target {
  font-size: 14px;
  color: #a546fc;
  margin: 0px;
  margin-bottom: 5px;
  position: relative;
  margin-top: -23px;
  padding-top: 20px;
}

.menu-category {
  text-align: left;
}

.menu-category-title {
  text-align: left;
  color: white;
  margin-left: 20px;
  margin-bottom: 10px;
}

.features-arrow {
  margin-left: 10px;
  font-size: 12px;
  margin-right: -10px;
  transition: all 0.3s;
}

.features-arrow.active {
  transform: rotate(-180deg);
  color: #a546fc;
}

.features-link {
  position: relative;
  transition: all 0.3s;
}

.features-link.active {
  color: #a546fc;
}

.features-menu {
  position: absolute;
  top: 100%;
  left: -150%;
  width: fit-content;
  background-color: #121212;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  padding: 20px;
  z-index: 10;
  border-radius: 12px;
  text-wrap:wrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
  transform: translateY(20px);
}

.features-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.features-menu-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.features-menu-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.menu-item {
  min-width: 400px;
  border: none !important;
  padding: 20px 20px 20px 20px !important;
  align-items: flex-start !important;
}

.menu-item:hover {
  border-radius: 9px !important;
}

.features-menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  color: #a546fc;
}

.features-menu-title {
  margin: 0;
  font-size: 16px;
  color: white;
}

.features-menu-description {
  margin: 0;
  font-size: 14px;
  color: gray;
  margin-top: 5px;
}

.explore-all-button {
  display: block;
  font-weight: bold;
  text-align: left;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  background: none;
  border: none;
  font-size: 16px;
  margin-left: 0px;
}

.explore-all-button:hover {
  transition: all 0.3s; 
  color: #a546fc;
  background: none;
}

.explore-all-icon {
  color: #a546fc;
  margin-left: 10px;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  transition: background-color 0.5s;
  z-index: 1000; /* Ensure the navbar is above other content */
  position:fixed;
  width: 100%;
  height: 120px;
}

.nav-container .navbar {
  margin: auto;
}

.nav-container.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Opaque black background */
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  transition: background-color 0.3s;
  min-width: 80%;
  margin: 0px auto;

}

.navbar .hamburger-btn {
  position:relative;
  top:0px;
  right:0px;
}

.navbar .hamburger-btn {
  display: none;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease; /* Smooth transition for sliding effect */
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
  transform: translateX(0); /* Slide in from the right */
  text-align: center;
  padding-top: 60px; /* Adjust the padding to match your navbar height */
  transition: all 1s;
}

.nav-link {
  margin: 10px;
  text-wrap: nowrap;
}

.sign-in-button {
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 1024px) {

  .features-target {
    font-size: 12px;
  }

  .nav-container {
    height: 65px;
  }

  .navbar .sign-in-button {
    margin-bottom: 25px !important;
  }
  

  .navbar .hamburger-btn {
    display: block;
    z-index: 9999;
  }

  /* Old Way (transitions didnt work)
  .nav-links {
    display: none;
    transform: translateX(100%); /* Hide the menu off-screen to the right 
    transition: all 1s;
  } */

  .nav-links {
    text-align: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width:100%;
    transform: translateX(100%);
    height: 100%;
    background-color: #121212;
    padding-top: 60px; /* Adjust the padding to match your navbar height */
    transition: transform 0.8s ease; 
  }

  .nav-links.open {
    transform: translateX(-0%); /* Slides in from the right */
    display: flex;
    max-height: 100vh; /* Limit height to enforce scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scroll bar */
    transition: transform 0.8s ease; /* Ensure a smooth slide effect */
  }

  .nav-link {
    margin: 0px 0px;
  }

  .sign-in-button {
    display: block;
  }

  .features-menu {
    position: relative;
    top: 0px;
    left: 0px;
    width: fit-content;
    background-color: #121212;
    box-shadow: none;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 10;
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0px);
    transition: all 0.5s ease;
    max-height: 0px;
  }

  .features-menu.active {
    border-bottom: 1px solid #242424;
    max-height: 8000px;
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
  }
  
  .features-menu-list {
  display: flex;
  flex-direction: column;
  background-color: blue;
  }

  .features-menu-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: purple !important;
  }
  
  .menu-item {
    min-width: 320px;
    border: none !important;
    padding: 20px 20px 20px 20px !important;
    align-items: flex-start !important;
  }

  .explore-all-button {
    display: block;
    text-align: center !important;
    margin: auto;
    align-self: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

}

/* Pricing -----------------------------------------------------------------------------*/

.pricing-plan .original-price {
  text-decoration: line-through;
  color: #777; /* Gray out the original price to emphasize it's no longer applicable */
}

.pricing-plan .promotional-price {
  color: #4CAF50; /* Green color to highlight the free offer */
  font-weight: bold;
}

.pricing-plan .promotional-message {
  color: #FF5722; /* Vibrant color to attract attention */
  font-weight: bold;
  margin-top: 5px; /* Spacing for better readability */
}

.slider-container {
  width: 90%;
  margin: auto;
}

.plan-badge {
  background-color: #212121;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

.pricing-section {
  text-align: center;
  padding: 40px 0;
  margin-bottom: 60px;
}

.pricing-plans {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.pricing-plan {
  border: 1px solid #212121;
  border-radius: 10px;
  padding: 40px;
  width: 315px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #121212;
  margin-top: 60px;
  position: relative; /* For the badge positioning */
}

.pricing-plan h3 {
  margin-top: 0;
  color: white;
}

.price {
  font-size: 24px;
  color: white;
  margin: 10px 0;
}

.features {
  margin: 40px 0px;
  list-style: none;
  padding: 0px;
  text-align: left;
  color: gray;
}

.features li {
  margin-bottom: 10px;
}

.cta-button {
  background-color: #a546fc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.disabled {
  background-color: gray;
}

.cta-button:hover {
  background-color: white;
  color: #a546fc;
}

.cta-button-selected {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  background-color: white;
  color: #a546fc;
}

.cta-button-selected:hover {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  background-color: white;
  color: #a546fc;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .plan-badge {
    top: -59px !important;
    left: 50%;
  }

  .pricing-plans {
    flex-direction: column-reverse;
    align-items: center;
  }

  .pricing-plan {
    width: 80%;
    margin-top: 30px;
  }

  .plan-badge {
    top: -45px;
    font-size: 12px;
  }
}



/* Team */

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
  width: 80%;
  margin-bottom: 80px;
}

.team-member {
  width: calc(33%);
  text-align: center;
  margin-top: 50px;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .team-member {
    width: 100%;
  }

  .team-container {
    width: 100%;
  }
}


/* Features Page */

/* Features.css */

.feature-image {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid #191919;
}

.feature-icon {
  margin-right: 15px; /* Space between icon and title */
}


.features-page {
  display: flex;
  gap: 20px;
  padding: 20px;
  width: 80%;
  margin-bottom: 60px;
}

.features-list {
  flex: 1;
  display: flex;
  flex-direction: column;
 /* gap: 10px; */
}

.feature-item {
  padding: 20px;
  background-color: #121212;
  border-radius: 5px;
  border-bottom: 1px solid #212121;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.feature-item:hover {
  background-color: #191919;
}

.feature-details {
  padding: 40px;
  background-color: #121212;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  height: max-content;
}

.feature-details-container {
  flex: 2;
  position: sticky;
  top: 100px; /* Adjust as needed */
  margin-left: 20px; /* Add some margin to prevent it from sticking too closely to the left */
}

.feature-details li {
  margin-bottom: 15px;
}

.feature-details h2 {
  margin-top: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features-page {
    flex-direction: column;
    width: 100%;
  }

  .features-list {
    order: 2; /* Move the list below the details on small screens */
  }

  .feature-details {
    order: 1; /* Move the details above the list on small screens */
    margin-bottom: 20px;
  }
}


/* Nav */

.nav-link {
  color: white;
  padding: 20px;
  background: none;
  border: none;
  font-size: 16px !important;
}

.nav-link:hover {
  color: #a546fc;
  padding: 20px;
  background: none;
  border: none;
  font-size: 16px;
}

/* Login-modal */

.login-modal {
  position: fixed; /* Use fixed positioning to make it stay in place even when scrolling */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* This centers the modal */
  width: 80%; /* You can adjust the width */
  max-width: 400px; /* And set a max-width */
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for some depth */
  z-index: 1000; /* Make sure it's above other content */
  border-radius: 8px; /* Optional: for rounded corners */
}

.login-modal p {
  color: #333; /* Dark gray text color */
  margin-bottom: 20px; /* Add some space below the text */
}

.login-modal button {
  display: block; /* Block level so it fills the container */
  width: 100%; /* Make the button as wide as the container */
  padding: 10px 0; /* Add some padding for better touch */
  background-color: #007bff; /* A nice blue background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 4px; /* Match the modal's border-radius */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.login-modal button:hover {
  background-color: #0056b3; /* A slightly darker blue on hover */
}


/* Create Deets */

.link-section {
  width: 100%;
}

.tags-section {
  width: 100%;
}

.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-box {
  background-color: #242424;
  color: white;
  padding: 0px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.remove-tag-button {
  background: none;
  border: none;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  padding: 0px !important;
  margin-bottom: 10px;
}

.remove-tag-button:hover {
  color: #a546fc;
  background: none !important;
}

.tags-input {
  border: 1px solid #ccc;
  padding: 5px;
  flex-grow: 1;
}

.address-section {
  width: 100%;
}

.address-input-container {
  position: relative;
  width: 100%;
}

.address-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}

.loading {
  position: absolute;
  background-color: #242424;
  padding: 10px;
  border-top: none;
  width: 100%;
  z-index: 1;
  color: gray;
  border-radius: 12px;
}

.suggestions {
  border-top: none;
  position: absolute;
  z-index: 1;
  background-color: #242424;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 12px;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  background-color: #242424;
  transition: background-color 0.3s;
}

.suggestion-item:hover {
  background-color: #484848;
}

.suggestion-border {
  border-top: 1px solid gray;
}


.registration-field > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px; /* Adds spacing between the input/select and the remove button */
}

.registration-field label {
  margin-bottom: 0px; /* Adds a little space above each input/select */
  font-weight: bold; /* Optional: Makes the label text bold */
}


/* Container for each registration field */
.registration-field {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between each element */
  margin-bottom: 10px; /* Adds space below each field row */
  margin-top: 30px;
}

.registration-field input,
.registration-field select {
  flex-grow: 1; /* Allows input and select to fill available space */
  padding: 8px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Rounded corners for inputs and selects */
  min-width: 300px;
}

.registration-field button {
  padding: 8px 12px;
  color: #f44336 !important; /* Red background for remove button */
  border: none;
  background: none;
  border-radius: 4px; /* Rounded corners for button */
  cursor: pointer; /* Indicates clickable button */
  margin-top: 30px !important;
}

.registration-field button:hover {
  background: none !important; /* Darker red on hover for visual feedback */
}


/* Organizer Dashboard */

.active-group {
  background-color: var(--accent-color) !important;
}

.info-context {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-context p {
  margin-right: 10px;
}

.context-icon {
  font-size: 12px;
}

.edit-cover-image {
  width: 300px;
  height: auto;
}

.org-analytics-page {
  margin-top: 100px;
}


.posts-container {
  max-width: 1200px; /* Adjusted for a wider container */
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Creates a responsive grid */
  gap: 20px; /* Space between grid items */
  padding: 20px 0px;
}

.post-item {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #121212;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s; /* Animation on hover */
}

.post-item:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.post-item img {
  width: 100%; /* Ensure image covers the width */
  height: 150px; /* Set a fixed height for images */
  object-fit: cover; /* Ensures the image covers the space neatly */
}

.post-details {
  padding: 10px;
}

.post-menu {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.attendees-count {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  margin-top: 5px;
  bottom: 15px;
  left: 15px;
}

.post-menu:hover {
  color: #a546fc;
}

.delete-action {
  color: red;
}

.menu-icon {
  cursor: pointer;
  font-size: 24px;
}

.dropdown-menu {
  position: absolute;
  bottom: 30px;
  right: 0;
  background: #242424;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 120px;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background: #484848;
}

.post-title {
  font-size: 16px; /* Adjusted font size */
  color: white;
  margin: 10px 0;
}

.post-date {
  font-size: 14px;
  color: #666;
}

/* Feel free to adjust paddings, margins, and font sizes further to match your design preferences */

.navigation-bar {
  border-bottom: 1px solid #333; /* Dark background color */
  overflow: hidden; /* Ensures the content fits within the navigation bar */
}

.navigation-bar nav ul {
  list-style-type: none; /* Removes the default list styling */
  margin: 0;
  padding: 0;
  display: flex; /* Displays the list items in a row */
  align-items: center; /* Centers items vertically */
}

.navigation-bar nav ul li {
  float: left; /* Aligns the list items side by side */
}

.navigation-bar nav ul li a {
  display: block; /* Makes the entire area clickable */
  color: white; /* Text color */
  text-align: center; /* Centers the text */
  padding: 14px 16px; /* Top and bottom padding, left and right padding */
  text-decoration: none; /* Removes the underline from links */
  font-size: 16px; /* Sets the font size */
}

.navigation-bar nav ul li a:hover {
  background-color: #ddd; /* Light background color on hover */
  color: black; /* Text color on hover */
}

/* Responsive styling (optional) */
@media screen and (max-width: 600px) {
  .navigation-bar nav ul li {
    float: none; /* Stack the links under each other instead of side by side */
  }
  
  .navigation-bar nav ul {
    flex-direction: row; /* Aligns the links vertically */
  }
}


/* Analytics & Engagement */

.analytics-container {
  display: flex;
  flex-direction: column;
}

.stat-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.stat-box {
  flex-basis: calc(25% - 20px);
  background-color: #121212;
  padding: 20px;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-box h2 {
  margin-bottom: 15px;
  font-size: 20px;
}

.graphs-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.graph {
  width: 100%;
  max-width: 800px;
}

.graph-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organizer-analytics-graph {
  width: 100%;
}

.dropdown-button {
  background-color: #121212;
  color: white;
  border-radius: 3px;
  margin-left: 15px;
  padding: 5px;
  border: none;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stat-boxes {
    flex-direction: column;
  }

  .stat-box {
    flex-basis: auto;
  }

  .graphs-container {
    padding: 10px;
}

.graph {
    max-width: 100%;
}
}




/* Posts Page */

.post-day {
  margin-top: 0px;
  position: absolute;
  left: -50px;
  margin-top: 0px;
  color: rgb(194, 194, 194);
}

@media (max-width: 1024px) {
  .post-day {
    left: 15px;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .post-day {
    margin-top: 50px;
    position: relative;
    left: 30px;
    margin-top: 0px;
  }
}

.privacy-indicator {
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: light;
  margin-left: 4px;
  color: var(--accent-color);
}

.privacy-indicator p {
  color: white !important;
}

/* Lock icon styling */
.privacy-indicator .fa-lock {
  margin-left: 6px;
  font-size: 12px;
  color: white;
}

/* Initially hide hamburger button for larger screens */
/* .hamburger-btn {
  display: none;
} */

@media only screen and (max-width: 600px) {
.OrgLogo {
  margin-right: 5px !important;
  width: 45px !important;
  object-fit: contain;
}
}

/* Hide NavBar and Show Hamburger button under 1024px */
@media only screen and (max-width: 1024px) {

  .sidebar .ProfileNav-Container {
      position: relative;
      bottom: -10px;
      width: 92.2%;
    width: 100%;
  }

  .NavBar {
    display: none;
  }

  .deets-nav-logo {
    position:absolute;
    top: 5px;
    left: 15px !important;
    width: 20% !important;
    max-width: 150px;
    height: 60px;
    object-fit: cover;
  }

  .deets-nav-logo h2 {
    display: none;
  }

  .SearchBar {
    max-width: 800px !important;
    width: 55% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    margin-right: 10px;
    font-size: 12px;
  }

  .SearchBar .fa-search {
    margin-left: 0px;
    margin-right: 2px !important;
  }

  .cog-button {
    padding-left: 0px !important;
  }
  
  .SearchBar input {
    flex-grow: 1;
    margin-right: 10px !important;
    padding: 10px 1px !important;
    padding-left: 5px !important;
    border: 0px !important;
    border-radius: 12px !important;
    box-shadow: 0 0 0 30px var(--input-background) inset !important;
    font-size: 14px;
    -webkit-appearance: none;
  }
  
  .hamburger-btn {
    display: block;
  }

  .sidebar {
    z-index: 999 !important;
  }

    
}

.sidebar.open {
  right: 0;
  height: 100%;
  overflow-y: auto;
}

.sidebar .Navigation {
  width: 100% !important;
  max-width: 350px;
  overflow-y: visible;
  height:max-content;
}

.post-search {
  display: none !important;
}

/* General styling for responsiveness */
@media only screen and (max-width: 768px) {

  .sidebar.open {
    position: fixed;
    width: 100%;
  }

  .sidebar .ProfileNav-Container {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .navigation-content {
    padding-left: 0px !important;
  }

}

/* Search Bar / Top Nav Styling */

.portal {
  background-color: gray;
  color:#242424;
  font-size: 9px;
  padding: 3px 6px;
  border-radius: 5px;
  margin: 0px;
  margin-left: 7px;
}

.community-sign-out:hover {
 color: #a546fc;
 cursor: pointer;
}

.community-select {
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
  transform: translateX(100%);
}

.community-select.open {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.3s;
  pointer-events:all;
  z-index: 101;
}

/*
.community-select.hidden {
  transform: translateX(100%);
  transition: all 0.3s;
  opacity: 0;
}
*/

.organization-dropdown {
  position: fixed;
  top: 15px;
  right: 40px;
  background: #242424;
  border: 0.25px solid #363636;
  border-radius: 9px;
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.org-dropdown-menu {
  border-radius: 4px;
  list-style: none;
  padding: 10px 0;
  z-index: 1000;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 0px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-header i {
  margin-left: 20px;
}

.org-dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 5px
}

.org-dropdown-menu li:last-child {
  margin-bottom: 0px;
}

.organization-dropdown .divier:last-child {
  margin-top: 0px;
}

.org-dropdown-menu li:hover {
  background: #484848;
}

.organization-dropdown:hover .dropdown-menu {
  display: block;
}


@media only screen and (max-width: 1024px) {
  .dropdown-header {

  }
  .searchResultsDropdown {
    width: 90% !important;
    align-self: center;
    left: 20px;
  }
  .organization-dropdown {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-header {
    font-size: 12px;
  }
}

.result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* spacing between items, adjust as necessary */
  flex-direction: row; /* ensure the flex direction is row (this is default but explicitly setting just in case) */
}

.result-item span{
  font-size: 14px;
  color: gray;
}

.deets-result-image {
  width: 50px;  /* adjust based on your needs */
  height: 50px;
  border-radius: 3px; /* this makes the image appear as a circle */
  margin-right: 10px;
  object-fit: cover; /* ensures images retain aspect ratio within the circle */
}

.result-image {
  width: 40px;  /* adjust based on your needs */
  height: 40px;
  border-radius: 50%; /* this makes the image appear as a circle */
  margin-right: 10px;
  object-fit: cover; /* ensures images retain aspect ratio within the circle */
}

.info h4 {
  margin: 0;
  margin-bottom: 5px;
  color: white;
}


.non-deets-result {
  /* Styles for users and circles results, if needed */
}


.filterContainer {
  display: flex;
  margin-right: 10px;
}

.filterContainer button {
  background-color: #191919;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filterContainer button:hover {
  background-color: #212121;
}

.filterContainer button.active {
  border-color: #a546fc;
  color: white;
}

input[type="text"] {
  padding: 6px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.searchResultsDropdown {
  width: 50%;
  height: 90vh;
  position: absolute;
  top: 90%;
  border-radius: 24px;
  background-color: #121212;
  max-height: 1600px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 30px;
  list-style-type: none;
}

.search-results {
  margin-top: 20px;
  overflow: auto;
}

.searchResultsDropdown li {
  padding: 15px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 12px;
}

.searchResultsDropdown li:hover {
  background-color: #191919;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 50%;
  max-width: 400px;
  height: 100%;
  background-color: black;
  padding: 20px;
  transition: right 0.3s ease-in-out;
  color: #fff;
  border-left: solid 1px #333;
  box-shadow: 0 0 20px rgb(0, 0, 0);
}

.hamburger-btn {
  position: fixed;
  top: 15px;
  right: 10px;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensures the button is above the sidebar */
  background: none;
}

.hidden-tutorial {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  display: none;
}


.hamburger-btn:hover {
  background: none;
}

.hamburger-btn .line {
  width: 1.5rem;
  height: 3px;
  margin-bottom: 5px;
  background: #fff; /* Change to desired color */
  transition: all 0.3s linear;
  border-radius: 24px;
}

/* Transform hamburger to X */
.hamburger-btn.open .line:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-btn.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-btn.open .line:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}


.top-nav {
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  background-color: var(--background-2-color);
  padding: 10px 0;
  z-index: 100;
  border-bottom: solid 1px var(--gray-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchBar {
  max-width: 900px;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background: var(--input-background);
  border-radius: 12px;
  padding-left: 15px;
  overflow: hidden;
}

.cog-button {
  display: block;
  position: relative;
  right: 0px;
  color: var(--primary-color);
}

.SearchBar .fa-search {
  color: gray;
  z-index: 0;
}

.fa-search {
  z-index: 1;
}

.SearchBar input {
  flex-grow: 1;
  padding: 10px;
  border: 0px !important;
  box-shadow: 0 0 0 30px var(--input-background) inset !important;
}

.SearchBar input:active {
  border: none !important;
}

.SearchBar input:focus {
  border: none !important;
}


.Explore {
  background-color: black;
  color: white;
  column-gap: 40px;
  margin-top: 75px;
}

.Explore a{
  text-decoration: none;
}

  /* Profile Box */

.ProfileNav-Container {
  width: 92.2%;
  border: 1px solid var(--gray-color);
  margin-right: 50px;
  padding: 10px 20px;
  height: fit-content;
  border-radius: 12px;
  display: flex; /* New - Make it a flex container */
  /* justify-content: center; /* New - Center content horizontally */
  align-items: center; /* New - Center content vertically */
  flex-direction: column; /* New - Arrange children vertically */
  margin-top: 30px;
}

.ProfileNav-Content {
  display: flex;
  justify-content: center; /* New - Center content horizontally */
  align-items: center; /* New - Center content vertically */
  flex-direction: row; /* New - Arrange children vertically */
}

.ProfileNav-Content .ProfileImage {
  width: 60px;
  height: 60px;
  border-radius: 50%; /* make image a circle */
  object-fit: cover;
}

.ProfileImage {
  border: 0.25px solid #242424;
}

.ProfileNav-Content h3 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 16px;
}

.ProfileNav-Content p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
}

.profile-info {
  text-align: left;
  margin-left: 10px;
}

.edit-profile {
  font-size: 12px;
  width: 12px;
}

.align-items-center {
  align-items: center;
}

.sign-out-btn {
  color: white !important;
  background-color: var(--accent-color);
  border: none;
  padding: 10px 20px !important;
  border-radius: 9px;
  font-size: 14px !important;
  font-size: 12px !important;
  transition: all 0.3s;
}

.sign-out-btn:hover {
  background-color: white !important;
  color: var(--accent-color) !important;
}

.edit-profile-btn {
  margin-left: 5px;
  background: none;
  border: none;
  padding: none;
  border-radius: 9px;
  font-size: 14px !important;
  font-size: 12px !important;
  width: 30px !important;
  background-color: var(--background-3-color);
  color: var(--primary-color);
}

.profile-buttons {
  margin-top: 15px;
  width: 100%;
  justify-content: center;
}

.ProfileNav-Content button:hover {
  background-color: white !important;
  color: #a546fc !important;
}

 /* Left navigation bar */
 

.Navigation {
  position: fixed;
  top: 70px;
  height: 100vh;
  padding: 20px 0px;
  border-right: 1px solid var(--gray-color);
  width: 250px;
  z-index: 99;
  overflow-y: auto;
}

.navigation-content {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  z-index: 100;
  justify-content: space-between;
}

.deets-nav-logo {
  position:absolute;
  top: 7px;
  left: 30px;
  width: 120px;
  height: 60px;
  object-fit: cover;
}

.org-nav-logo {
  position:absolute;
  top: 7px;
  left: 30px;
  width: 120px;
  height: 60px;
  object-fit: cover;
}

.deets-nav-logo h2 {
  color: var(--primary-color);
  font-size: 16px;
}

@media (max-height: 720px) {
  .ProfileNav-Container {
  position: relative;
  width: 92.2%;
  }

  .Navigation {
    padding-bottom: 90px;
  }

  .org-dropdown-menu {
    height: 80%;
    max-height: 500px;
  }
}

@media (max-width: 1200px) {
  .deets-nav-logo h2 {
    color: white;
    max-width: 150px;
    text-wrap: wrap;
  }

  .Navigation {
    padding-bottom: 150px;
  }
}

.deets-nav-logo button {
  font-size: 18px;
  background: none;
  border: none;
}

.deets-nav-logo button:hover {
  font-size: 18px;
  background: none;
  border: none;
}

.Navigation button {
  color: var(--primary-color);
  border: none;
  padding: 10px 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Navigation .NavButton {
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  padding: 13px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 1s ease;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  text-align: left;
}

.Navigation .NavButton:hover {
  background-color: var(--background-2-color);
  transition: background-color 0.5s ease;
}

.NavButton.selected {
  border-left: 4px solid var(--accent-color);
  font-weight: bold;
  background-color: var(--background-2-color);
}


.cover-image {
  background-color: #121212;
  border-radius: 12px;
  border-width: 0px;
}

.post-button {
  color: white !important;
  background-color: var(--accent-color) !important;
  padding: 15px 30px !important;
  border-radius: 12px !important;
  margin-top: 10px;
  width: 92%;
  font-size: 16px !important;
}

.post-button:hover {
  background-color: white !important;
  color: var(--accent-color) !important;
  padding: 15px 30px !important;
  border-radius: 12px !important;
  margin-top: 10px;
}

.Navigation .NavButton i {
  margin-right: 10px;
}

.OrgInfo {
  display: flex;
  align-items: center;
  width: 500px;
}

.OrgLogo {
  width: auto;
  max-width: 100px;
  height: 50px;
  object-fit: contain;  /* Ensures the image covers the entire area without distortion */
  margin-right: 10px; /* Space between logo and organization name */
  border-radius: 3px;
}

.OrgSignOut {
  background-color: #f44336; /* Red background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 15px; /* Space between organization name and sign-out button */
  transition: 0.3s; /* Smooth transition for hover effect */
}

.OrgSignOut:hover {
  background-color: #d32f2f; /* Slightly darker red on hover */
}

.App-content {
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.post {
  width: 100%;
  border-radius: 24px;
  display: grid; /* New - This will make the post items align in a grid */
  grid-template-columns: 0.5fr 1fr;
  color: gray;
  margin-top: 30px;
  transition: all 0.5s
}

.post:hover {
  transition: all 0.5s;
  cursor: pointer !important;
  transition: all 0.5s;
}

.post img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  border: 0.25px solid gray; /* New - Add a thin gray border */
  transition: all 0.5s;
}

@media only screen and (max-width: 1300px) {
  .post img {
  width: 100%;
  }
}

/*
.post:hover .post-image {
  height: 250px;
  transition: all 0.5s;
} */

.post:hover .divider {
  border-color: var(--accent-color);
  transition: all 0.5s;
}

.post:hover .post-divider {
  border-color: var(--accent-color);
  transition: all 0.5s;
}

.post-content {
  width: 100%;
  font-family: 'Albert Sans';
  padding-left: 50px; 
}


.post-content h2 {
  color: var(--text-color);
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.post-content h3 {
  color: var(--text-color);
  margin-top: 15px;
  margin-bottom: -0px;
  font-size: 14px;
}

.post-content p {
  color: gray;
  font-size: 14px;
}

/* Notifications */

.request-timestamp {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}

.follows-you-text {
  height: 0px;
  font-size: 12px;
  color: #888;
  margin: 3px;
  margin-bottom: 10px;
}

.notification {
  padding: 15px;
  margin: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.notification.warning {
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.notification .close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: inherit;
}


.request-card {
  display: flex;
  align-items: center;
  background-color: #000000;
  border: 0.5px solid #191919;
  padding: 15px;
  margin: 10px 0;
  border-radius: 12px;
}

.request-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.request-card p {
  flex-grow: 1;
}


/* Single Post Page */

/* CSS for the notice paragraph */
.notice-paragraph {
  margin-bottom: 90px;
  font-size: 16px; /* Example font size */
  margin-bottom: 30px; /* Adds some space below the paragraph */
  background-color: #121212;
  padding: 10px 20px;
  border-radius: 12px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

/* CSS for the organization name to make it purple */
.organization-name {
  color: var(--accent-color);
  font-weight: bold; /* Makes the organization name bold */
}


.accent-color {
  color: var(--accent-color);
}


.count {
  cursor: pointer;
}

.count:hover {
  color: white;
}

.icon.liked {
  color: var(--accent-color);
}

/* Attendees Screen */

.custom-dropdown {
  position: relative;
  display: inline-block;
  transition: all 0.5s;
}

.dropdown-btn {
  background-color: #f9f9f9;
  color: black;
  padding: 5px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-align: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content button {
background: none;
border: none;
text-align: center;
padding: 15px;
}

.dropdown-content button:hover {
color: #a546fc;
background: none;
  }

.custom-dropdown:hover .dropdown-content {
  display: block;
}

.custom-dropdown:hover .dropdown-btn {
  background-color: #242424;
}


.checked-in {
  color: green;
  font-weight: bold;
  background-color: rgba(0, 128, 0, 0.08);
  font-size: 14px;
  font-weight: 100;
  padding: 10px;
  width:fit-content;
  border-radius: 9px;
}

.not-checked-in {
 color: gray !important;
 font-size: 14px;
 background: none;
}

.attendees-container-post {
  position: absolute;
  top: 700px;
  margin-left: 30px;
}

.attendees-container {
  position: relative;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 100px;
}

.attendees-container h2{
  font-size: 36px;
  position: relative;
  left: 30px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.attendees-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 500px;
}

.attendee-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Content is aligned to the left */
  margin-bottom: 1rem;
  border: 0.5px solid #494949;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.5s;
  width: 100%; /* Make the li element take the full available width */
  max-width: 500px;
}

.attendee-name {
  flex-grow: 1; /* Take up all available space */
  margin: 0 5px; /* Optional: Give it some margin for spacing */
  font-size: 16px !important;
}

/* Hover effect for the organizer link */
.attendee-item:hover {
  cursor: pointer; /* Changes the cursor to a hand, indicating a clickable area */
  background-color: #121212; /* Changes the background color to dark gray when hovering */
  border: 0.5px solid var(--accent-color);
  transition: all 0.5s;
}

.attendee-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.attendee-name {
  font-size: 18px;
}

/* This is to reset the color and remove underlines from the link */
.attendee-item, .attendee-item:visited {
  color: inherit; /* Inherits the color from its parent (or any defined color in a higher specificity) */
  text-decoration: none;
  display: flex; /* Using flexbox for alignment */
  align-items: center; /* Vertically center-aligns the content */
}

/* Keeps the organizer name's color unchanged */
.attendee-name {
  margin-left: 10px; /* Adds some space between the image and the name */
}

/* Override the default link color and underline */
.attendee-link, .attendee-link:visited {
  color: inherit; /* Inherits the color from its parent or any defined color in higher specificity */
  text-decoration: none; /* Removes underline */
}

/* Add a hover effect for the link */
.attendee-link:hover {
  color: inherit; /* Keeps the color unchanged during hover */
}

/* Memory grid styles */

/* Photo Preview Section */
.photo-preview-section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between images */
  margin-top: 16px;
  align-items: center;
  width: 100%;
  padding: 35px;
}

.photo-preview-section img {
  max-width: 500px; /* Adjust to desired preview size */
  max-height: 180px;
  object-fit: cover;
  border-radius:  12px;
  border: 0.5px gray solid;
}

.add-photos-icon {
  margin-right: 10px !important;
}

.add-photos-button, .upload-photos-button {
  font-size: 18px;
  background-color: #121212;
  color: var(--accent-color);
  padding: 10px 30px;
  margin-left: 35px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  width: 200px;
}

.upload-photos-button {
  align-self: center;
  margin: 0px;
}

.memories-upload-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%; 
}

.photo-preview-item {
  position: relative;
  display: inline-block;
}

.remove-photo-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #222222; 
  padding: 0px 6px !important;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 50%; 
  font-size: 20px;
}

.remove-photo-icon:hover {
  color: rgb(255, 80, 80)
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-overlay img {
  max-width: 80%;
  max-height: 80%;
}

.memory-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 2em;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.left-arrow {
  left: 5%;
}

.right-arrow {
  right: 5%;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  color: #FFF;
}


.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.memories-container .download-button, .memories-container .delete-button {
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  transition: all 0.3s ease;
  background-color: #121212;
}


.download-button:hover {
  color: #a546fc;
  background-color: #191919;
}

.delete-button:hover {
  color: rgb(255, 80, 80);
  background-color: #191919;
}

.memories-container {
  position: relative;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 100px;
}

.memories-container h2{
  font-size: 48px;
  position: relative;
  left: 30px;
  margin-top: 40px;
  margin-bottom: 0px;
}

.back-button {
  position: absolute;
  top: 0px; /* Adjust to position it perfectly */
  left: 45px; /* Adjust to position it perfectly */
  background-color: transparent;
  border: none;
  font-size: 24px; /* Adjust size as needed */
  color: #fff; /* Adjust color as needed */
  cursor: pointer;
  transition: color 0.3s ease;
}

.back-to-dash {

}

.back-button:hover {
  color: #555; /* Adjust hover color as needed */
}

.memories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.memory-item {
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.memory-item:hover {
  transform: scale(1.05);
}

.memory-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.memory-item div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.memory-item div img {
  width: 40px; /* Adjust as needed */
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.memory-item p {
  margin: 0;
  font-size: 14px; /* Adjust as needed */
  color: #fff; /* Adjust as needed */
}

@media (max-width: 480px) {
  .memories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 0px;
    max-width: 100%; /* Ensure it does not exceed the container */
    margin: 0 auto;
    box-sizing: border-box; /* Include padding in the element's total width and height */
  }
}


.not-joined-button {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  background-color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 5px 25px;
  border-radius: 9px;
  cursor: pointer;
}

.joined-button {
  background-color: var(--accent-color);
  color: white;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 9px;
  cursor: pointer;
}


.organizer-dashboard-btn {
  background-color: #121212;
  color: var(--accent-color);
  padding: 10px 30px;
  border-radius: 9px;
  margin-left: 10px;
  font-size: 16px;
}

.organizer-dashboard-btn:hover {
  background-color: #242424;
  color: var(--accent-color);
  padding: 10px 30px;
  border-radius: 9px;
  margin-left: 10px;
  font-size: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center; 
  z-index: 99;
}

.no-background {
  background: none !important;
}

.modal-content {
  background-color: #121212;
  padding: 20px 40px;
  border-radius: 24px;
  width: 70%;
  max-width: 600px;
  height: 500px;
  z-index: 9999;
  overflow-y: auto;
}

.new-circle-modal {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1024px) {
  .new-circle-modal {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .modal-content {
    width: 90%;
  }
}

.modal-footer {
  margin-top: 10px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 30px;
}

.circle-information-section {
  width: 100%;
}

.circle-members-section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: flex-start; /* Align items at the left */
  flex-direction: column; /* Stack children vertically */
  overflow-y: auto;
}


.close-button {
  cursor: pointer;
  float: right;
  font-size: 24px;
  color: white;
}


.post-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensures the container takes up the full height of the viewport */
  min-width: 100vw;
  overflow-x: hidden;
  position: relative;
  top: 100px; /* Optional: Ensure no margin at the top */
  justify-content: center;
}

.post-page .Navigation {
  border: none;
}

#post-page .organization-dropdown {
  margin-right: 30px;
}

.organizer-dashboard .organization-dropdown {
  margin-right: 30px;
}

.attendees-container .organization-dropdown {
  margin-right: 30px;
}

.post-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.single-post {
  width: 80%;
  border-radius: 24px;
  display: grid; /* New - This will make the post items align in a grid */
  grid-template-columns: 0.5fr 1fr;
  column-gap: 40px;
  color: gray;
  transition: all 0.5s;
  margin-bottom: 120px;
}

.single-post:hover {
  background-color: #00000000;
  transition: all 0.5s;
  cursor: default;
  padding: 0px;
}

.single-post img {
  border: 0.25px solid gray; /* New - Add a thin gray border */
}

.single-post-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  border: 0.25px solid gray; /* New - Add a thin gray border */
  transition: all 0.5s;
}

.single-post:hover .post-image {
  height: 100%;
  transition: all 0.5s;
}

.single-post-content {
  width: 100%;
  font-family: 'Albert Sans'; 
}

.single-post-content h1 {
  padding: 10px 0px 0px;
  font-size: 36px;
  color: white;
  margin: 15px 0px;
  font-weight: 600;
}

.single-post-content h3 {
color: white;
font-weight: 600;
font-size: 16px;
}

.single-post-content p {
  font-size: 16px;
}

.qr-code-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 300px;
}

.qr-code-section h3 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2rem;
}

.checkin-section {
  margin-top: 20px;
  text-align: center;
}

.checkin-section button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.checkin-section p {
  margin-top: 10px;
}

.join-button {
  background-color: #007bff; /* Blue color */
  border: none;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 5px; /* Gap between the icon and text */
  margin-left: 10px; /* Add some space between the title and the button */
}

.join-button:hover {
  background-color: #0056b3; /* Slightly darker blue for hover */
}

.join-button:disabled {
  background-color: #a6a6a6; /* Grey color for the disabled state */
  cursor: not-allowed;
}

.join-button svg {
  width: 1em;
  height: 1em;
}

.organizer-information {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  width: 80%;
}

/* General CSS for the attendees' section */
.attendees-section {
 /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(255, 0, 255, 0.1);
}

.attendees-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;;
}

.attendees-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.attendees-section li {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.attendees-section li:last-child {
  border-bottom: none;
}

/* Hover effect for each attendee for better UX */
.attendees-section li:hover {
  background-color: #eee;
}

/* Additional styling for checked in attendees */
.checked-in p {
  color: green;
}

/* Additional styling for not checked in attendees */
.not-checked-in p {
  color: red;
}

.host .host-info {
  display: flex;
  align-items: center;
}

.checkin-section .checkin-btn {
  background-color: #a546fc;
}

/* Single Post Responsive */

@media (max-width: 768px) {
  .host {
    flex-direction: column;
    align-items: flex-start !important; /*had to add important so it left aligns on mobile */
  }

  .organizer-dashboard-btn {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.App-header {
  width: 100%;
  max-width: 1000px;
  margin-left: 0vw;
  padding-bottom: 90px;
}

@media only screen and (max-width: 1024px) {
  .post:hover .post-image {
    transition: all 0.5s;
  }

  .post:hover {
    background-color: black;
    transition: all 0.5s;
    cursor: pointer;
    padding: 0px !important;
  }

.post-image {
  align-self: center;
}

  .Explore {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .organizers-page {
    display: flex;
    flex-direction: column;
  }

  .organizers-content {
    display: flex;
    flex-direction: column;
    width: 400px;
  }

  .Navigation {
    position: relative;
    width: 100%;
    border-right: none;
    margin-right: 0;
    padding: 20px;
  }

  .Navigation button {
    width: 100%;
  }

  .Navigation .NavButton {
    width: 100%;
  }

  /*
  .ProfileNav {
    position: relative;
    width: 100%;
    height: auto;
    border: none;
    margin: 20px 0;
  } */

  .App-header {
    width: 100%;
    padding: 0 20px;
    padding: 0px 60px;
    margin-left: 0px;
  }

  .deets-time-text {
    text-align: center;
    margin: 60px;
  }

  .post {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 80px;
  }

  .post-content {
    padding: 0px 10px; 
  }

  .post .host {
    margin-top: 20px;
    flex-direction: row;
  }

  .single-post {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .single-post-image{
    align-self: center;
  }
}

/* Single Post Responsive */

@media only screen and (max-width: 550px) {

  .post {
    grid-template-columns: 1fr;
  }

}

@media only screen and (max-width: 768px) {

  .App-header {
    width: 100%;
    padding: 5px;
  }

  .post .divider {
   margin-top: 40px;
   margin-bottom: 0px;
   display:none;
  }

  .post img {
    height: 300px;
  }

  .post {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .single-post-content h1 {
    font-size: 24px;
  }
  
  .single-post-content h3 {
  color: white;
  font-weight: 600;
  font-size: 14px;
  }
  
  .single-post-content p {
    font-size: 14px;
  }
  
}


.collapsed {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expanded {
  display: block;
  white-space: pre-line;
}

.button {
  background-color: #121212;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #1a1a1a;
}

.count {
  display: flex;
  align-items: center;
}

.count i {
  margin-right: 5px;
}

.divider {
  border: none;
  border-top: 1px solid rgb(50, 50, 50);; /* Update to gray color */
  margin: 10px 0;
  transition: all 0.5s;
}

.post-divider {
  position: relative;
  bottom: -30px;
  border: none;
  border-top: 1px solid var(--gray-color); /* Update to gray color */
  margin: 10px 0;
  transition: all 0.5s;
}

.host {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-bottom: -20px;
}

.host .host-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.host p {
  font-size: 14px;
  margin: 0;
}

/*
textarea {
  font-size: 18px;
  background-color: black;
  border: 1px solid transparent;
  outline: none;
  padding: 15px;
  margin: 5px;
  width: 350px;
  border-radius: 12px;
  box-shadow: 0 0 0 30px #121212 inset;
  color: white;
} */

textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--input-background);
  border: none;
  border-radius: 9px;
  font-size: 18px;
}

textarea {
  border-radius: 12px;
  min-height: 100px;
  margin-top: 10px;
  margin: 5px;
  color: white;
  border: 1px solid #191919;
}

input:-webkit-autofill{
  -webkit-text-fill-color: white;
}

input:focus {
  border-width: 1px;
  border-color: #a546fc;
  transition: all 0.3s ease;
}

input.error {
  border-width: 1px;
  border-color: darkred;
}

/* Auth Page */



.auth-page {
  height: 100%;
  width: 100%;
  background-size: 200vh;
  background-position: top;
  background-repeat: no-repeat;
  background-position-y: 300px;
  overflow-x: hidden;
}

.auth-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* This will make the container take up the full height of the viewport */
  min-width: 100vw;
}

.auth-page input {
  max-width: 350px;
  box-shadow: 0 0 0 30px #121212 inset;
  border-radius: 12px;
}

.auth-page button{
background-color: #a546fc;
padding: 15px;
border-radius: 12px;
border-width: 0px;
width: 350px;
margin-top: 20px;
color: white;
font-weight: 600;
font-size: 18px;
cursor: pointer;
}

.auth-page button:hover{
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border-width: 0px;
  width: 350px;
  margin-top: 20px;
  color: #a546fc;
  font-weight: 600;
  font-size: 18px;
  }

.auth-page .logo{
  width: 300px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  }

.logo {
  width: 30px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.or-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.line {
  flex-grow: 1;
  height: 1px;
  border-color: #121212;
  width: 155px;
}

.or {
  padding: 0 10px;
  color: gray;
}

.org-btn {
  background-color: #4b0082; /* change the color to your preferred color */
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.spinner {
  border: 4px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 15px;
}

.register-prompt {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.register-link {
  color: #8a00ed;
  cursor: pointer;
 /* text-decoration: underline; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 1024px) {
.auth-page h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
}


/*
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
} */

/* Home Page */

.header-buttons {
  margin-top: 60px;  /* Space above the buttons */
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.navbar .navlogo {
  width: 150px;
  height: auto;
  margin-right: 10px;
}

.homepage-container {
  width: 100%;
}

.header-content {
  padding: 100px;
}

.homepage-header .logo {
  width: 80%;
  min-width: 350px;
  max-width: 640px;
}


.homepage-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.homepage-desc {
  font-size: 20px;
  color: #a9a9a9;
  font-weight: normal;
  margin-bottom: 20px;
  max-width: 600px;
}

.homepage-header .homepage-desc {
  font-size: 24px;
  color: white;
  max-width: 800px;
}

.message-alert {
  display: block;
  background-color: #a446fcaf;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  max-width: 800px;
  width: 90%;
  text-align: center;
  margin: auto;
  margin-top: 30px;
}

.header-sign-in {
  width: 230px;
  padding: 15px 85px;
  background-color: #a546fc;
  color: #fff;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
  transition: all 0.5s
}

.header-sign-in:hover {
  background-color: white;
  color: #a546fc;
}

.demo-button {
  width: 230px;
  padding: 15px 40px;
  background-color: #00000000;
  border-width: 2px;
  border-color: #a546fc;
  color: #a546fc;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
  transition: all 0.5s
}

.no-margin{
  margin-left:0px;
}

.demo-button:hover {
  border-color: #fff;
  color: #fff;
  transition: all 0.5s
}

.app-store-badge {
  width: 180px;
}

.app-store-badge:hover {
  cursor: pointer !important;
}

.phones {
  width: 100vw;
  margin-top: -120px;
}

.homepage-video {
  height: 800px;
}

.video-container {
  border: 1px solid rgb(43, 43, 43);
  border-radius: 24px;
  width: 66%;
  min-width: 300px;
  margin-top: 30px;
}

.homepage-video {
  width: 100%;
  height: auto;
  border: none;
}

.message {
  text-align: center;
  font-size: 48px;
  width: 80%;
  padding: 0px;
  margin: 0px auto;
}

.homepage-message {
  margin: 30px auto;
  text-align: center;
}

.homepage-message p {
 font-size: 18px;
}

.message {
  /* Sets the initial color to red and end color to blue */
 /* background: linear-gradient(to bottom, red, blue); */
  /* Clips the background to the text */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  /* Makes the text transparent so the background shows through */
  color: white;
}

.highlight {
  /* Sets the initial color to red and end color to blue */
 /* background: linear-gradient(to bottom, red, blue);  */
  /* Clips the background to the text */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  /* Makes the text transparent so the background shows through */
  color: transparent;
}

.icon-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 60px 0;
  width: 500px;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.about-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
}

.about-row {
  display: flex;
  flex-direction: column;
}

.column {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.column-image {
  width: 100%;
  max-width: 300px;
  height: 600px;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.column-headline {
  font-size: 24px;
  margin-top: 20px;
}

.column-text {
  margin-top: 10px;
  color: #888;
  width: 300px;
  font-size: 18px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
  margin-bottom: 20px;
}

.submit-button{
  width: 100%;
  padding: 15px 40px;
  background-color: #a546fc;
  color: #fff;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px; 
  margin-top: 20px;
}

.form-title {
font-size: 48px;
margin: 0px;
}

.form-desc {
  font-size: 18px;
  color: gray;
  font-weight: normal;
  }

.demo-form-container {
  width: 90%;
  max-width: 500px;
}

.form-group input {
  width: 100%;
  max-width: 500px;
}

.form-group button {
  width: 100%;
}

.success-message,
.error-message {
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.success-message {
  color: lightgreen;
}

.error-message {
  color: rgb(177, 50, 50);
}

.row .column {
  text-align: left;
}

.about-row .column {
  text-align: left;
}

.about-row-2 .column {
  text-align: left;
}

.icon-section .fa-solid {
  color: #a546fc;
  font-size: 36px;
}

.features-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.features-section .column {
  flex: 1;
  text-align: left;
  padding: 40px;
  width: 100%;

}

.features-section .column-image {
  width: 100%;
  max-width: 800px;
  height: 500px;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in 1s ease-in-out forwards;
}

.features-section h3 {
  font-size: 42px;
  margin-bottom: 20px;
}

.features-section .column-text {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in-up 1s ease-in-out forwards;
}

.features-list {
  list-style: none; /* removes bullet points */
  padding-left: 0; /* removes default padding */
}

.feature-item {
  display: flex; /* aligns icon and text horizontally */
  align-items: center; /* centers items vertically */
  margin-bottom: 0px; /* space between items */
}

.feature-item .fa-solid {
  margin-right: 10px; /* space between icon and text */
  font-size: 20px; /* size of the icon */
  width: 24px;
}

.footer-container {
  background-color: #000000;
  width: 100%;
  border-top: 1px solid #121212; 
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 60px;
  background-color: #000000;
  width: 90% !important;
  margin: auto;
  border-top: 1px solid #121212; 
}

.footer h3 {
  font-size: 24px;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
}

.footer-logo {
  width: 250px;
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-links a {
  font-size: 32px;
  color: #fff;
  text-decoration: none;
  padding: 20px;
}

@media only screen and (max-width: 1024px) {
  .homepage-title{
    font-size: 32px !important;
  }

  .homepage-desc {
    font-size: 18px !important;
  }

  .cta-buttons {
    margin-top: 30px;
  }

  .navbar {
    width: 90%;
    margin-top: -30px;
  }
  .video-container {
    width: 90%;
  }

  .icon-section {
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;

  }

  .homepage-header {
    background-size: 280vh;
  }

  .header-content {
    padding: 100px 0px;
    overflow: hidden;
  }

  .header-buttons{
    padding-top:0px;
  }

  .header-buttons .demo-button{
    margin-top:0px;
  }

  .features-section {
    flex-direction: column;
  }

  .about-section {
    flex-direction: column;
  }

  .about-section img {
    height: 400px;
  }

  .about-row {
    display: flex; 
    flex-direction: row;
  }

  .about-row-2 {
    display: flex;
  }

  .about-row-2 .column{
    text-align: right;
  }
  
  .footer {
    flex-direction: column;
    width: 100%;
    align-items: left;
  }
}

@media only screen and (max-width: 768px) {
  .navbar .navlogo {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  .homepage-header h1{
    font-size: 28px;
  }
  .homepage-header h2{
    font-size: 18px;
    padding: 0px 20px;
  }

  .homepage-header {
    background-size: 240vh;
  }

  .message {
    font-size: 28px;
    padding: 20px 0px;
  }

  .about-row {
    display: flex; 
    flex-direction: column;
  }

  .about-row-2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-row-2 .column{
    text-align: left;
  }

  .form-title {
    font-size: 36px;
    margin: 0px;
    }
    
    .form-desc {
      font-size: 16px;
      color: gray;
      font-weight: normal;
      }
}

/* Organization Search Page */

.org-search {
  height: fit-content;
  overflow: auto;
}

.org-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #121212;
  border-radius: 12px;
  cursor: pointer;
}

.org-item:hover {
  background-color: #a446fc0e;
}

.org-item img {
  height: 50px;
  margin-right: 10px;
}

.org-container {
  z-index: 99;
  width: 30%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  background-color: #0d0d0d;
}

.org-container input {
  margin: 20px;
  width: 100%;
  max-width: 400px;
}

/* Organization Page */
.organization-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background: 
    linear-gradient(to top, rgb(13, 0, 22) 0%, rgba(60, 71, 173, 0) 100%),
    linear-gradient(135deg, var(--accent-color), var(--accent-color));
}

.deets-logo-top-left {
  position: absolute;  /* This ensures the logo stays at the top left regardless of the other content */
  top: 10px;          /* Adjust as needed for desired margin from the top */
  left: 60px;         /* Adjust as needed for desired margin from the left */
  width: 150px;        /* Adjust for your logo's size */
  height: auto;
  z-index: 10;        /* Ensures the logo stays on top of other content */
}

.deets-logo-link {
  top: 10px;          /* Adjust as needed for desired margin from the top */
  left: 10px;
  display: block;
  position: absolute;
  z-index: 10;
}


.organization-page button {
  background-color: #a546fc;
  color: #fff;
  padding: 10px 10px;
  width: 200px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  margin: 15px;
  z-index: 2;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  z-index: 2;
  height: 100px;
}

.org-logo {
  width: auto; /* Adjust the width as needed */
  height: 100px; /* Maintain aspect ratio */
  margin-right: 20px;
  border-radius: 12px;
}

.organization-page .divider {
  width: 1px;
  height: 50px; /* Adjust the height as needed */
  background-color: white; /* Gray color for the divider */
  margin: 0 20px; /* Adjust the margin as needed */
}

.deets-logo {
  width: auto; /* Adjust the width as needed */
  height: 170px; /* Maintain aspect ratio */
}

.organization-page .org-title {
  font-size: 48px;
  z-index: 2;
  margin: 10px;
}

.carousel-container {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  gap: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
  z-index: 2;
  align-items: center;
}

.carousel-post {
  position: relative;
  flex: 0 0 500px;
  height: 300px;
  /* add necessary padding or margin here */
}

.carousel-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 23px;
  filter: grayscale(30%);
  opacity: 60%;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-radius: 24px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.carousel-post::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* adjust as necessary */
  background: linear-gradient(to top, rgb(14, 0, 17) 0%, rgba(0,0,0,0) 100%);
  border-radius: 24px;
  opacity: 80%;
}

.carousel-post-title {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  bottom: 15px;
  left: 20px;
  z-index: 1;  
  /* add necessary padding, font styles here */
}

.organization-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
}

.organization-info p {
  margin: 10px;
  color: black;
}

.counts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.counts-2 {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.count {
  display: flex;
  align-items: center;
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: gray;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  color: #121212;
  font-size: 12px;
}

.date-icon,
.location-icon {
  margin-right: 8px;
  margin-top:2px;
}

.post-icon {
  display: flex;
  align-items: top;
}

/* Join Organization Page */
.join-organization-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background: 
    linear-gradient(to top, rgb(13, 0, 22) 0%, rgba(60, 71, 173, 0) 100%),
    linear-gradient(135deg, #930000, #a546fc);
}

.join-organization-page button {
  background-color: #a546fc;
  color: #fff;
  padding: 15px 10px;

  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  margin: 15px;
  z-index: 2;
}

.deets-join-logo {
  position: absolute;
  top: -20px;
  left: 30px;
  width: 150px;
}

.join-org-logo {
  width: auto; /* Adjust the width as needed */
  height: 100px;
  margin-right: 20px;
}

.login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  min-width: 350px;
  padding: 20px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.login-modal h2 {
color: black;
}

.login-modal form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-modal label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.login-modal-input {
box-shadow: 0 0 0 30px white inset !important;
background-color: white !important;
color: black;
margin-bottom: 20px;
width: 100%;
margin-left: 0px;
margin-top: 5px;
width: 100% !important;
}

.login-modal button {
  background-color: #a546fc;
  font-size: 16px;
  border-width: 0px;
  margin: 10px 0px;
}

.login-modal .sign-in-btn {
  padding: 10px 40px;
  margin: 10px auto;
}

.login-modal-input:-webkit-autofill{
  -webkit-text-fill-color: black;
}

.login-modal .create-account-btn {
  background: none !important;
  color: #a546fc !important;
  margin: 0px !important;
  text-align: center !important;
  padding: 5px !important;
}

.invite-message {
  font-weight: lighter;
  margin-top: 30px;
}

.back-to-sign-in-btn {
  background-color: #f9f9f9 !important;
  color: #a546fc !important;
}

.modal-overlay {
  display: none; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black with opacity */
  z-index: 999; /* Ensures it's on top */
  align-items: center;
  justify-content: center;
}

.modal-overlay.active {
  display: flex; 
}

.login-modal {
  background-color: #fff;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensures it's on top of the overlay */
}


@media only screen and (max-width: 1024px) {

  .counts-2 {
    width: 100%;
  }

  .deets-logo-top-left {
    position: absolute;  /* This ensures the logo stays at the top left regardless of the other content */
    top: -20px;          /* Adjust as needed for desired margin from the top */
    left: 20px;         /* Adjust as needed for desired margin from the left */
    width: 120px;        /* Adjust for your logo's size */
    height: auto;
    z-index: 10;        /* Ensures the logo stays on top of other content */
  }

.post:hover .post-img {
  height: 200px;
}

.post:hover {
  padding: 20px;
}

  .organization-page .org-title {
    text-align: center;
    font-size: 32px;
    z-index: 2;
  }

  .logo-container {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .carousel-container {
    gap: 0px;
    margin-bottom:40px;
    display: none;
  }

  .carousel-post {
    flex: 0 0 420px;
  }


  .carousel-post {
    width: 400px;
    height: 200px;
  } 

  .carousel-post::after {
    width: 400px;
  }

  .carousel-post img{
    width: 400px;
  } 

}

@media only screen and (max-width: 768px) {
  .carousel-post img{
    width: 350px;
  } 

  .carousel-post::after {
    width: 350px;
  }

  .carousel-post {
    flex: 0 0 370px;
  }


  .carousel-post {
    width: 400px;
    height: 200px;
  } 

  .organization-page .org-title {
    font-size: 24px;
    padding: 10px;
  }

  .org-logo {
    width: auto; /* Adjust the width as needed */
    height: 80px; /* Maintain aspect ratio */
  }
  
  .organization-page .divider {
    width: 1px;
    height: 50px; /* Adjust the height as needed */
    background-color: white; /* Gray color for the divider */
    margin: 0 10px; /* Adjust the margin as needed */
  }
  
  .deets-logo {
    width: auto; /* Adjust the width as needed */
    height: 150px; /* Maintain aspect ratio */
  }
}

/* Home Page */

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar .sign-in-button {
  width: 130px;
  padding: 10px 20px;
  background-color: #a546fc;
  color: #fff;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
  transition: all 0.5s
}

.navbar .explore-deets-button {
  width: 130px;
}

.organization-page .sign-in-button {
  background-color: var(--accent-color);
}

.sign-in-button:hover {
  background-color: #fff;
  color: var(--accent-color);
  transition: all 0.5s
}

.homepage-container {
  width: 100%;
}

.homepage-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(./parallax.png);
  background-size: cover;
  background-size: 240vw;
  background-repeat: no-repeat;
  background-position: top;
  background-position-y: -100px;
  width: 100%;
  overflow: hidden;
  padding-top: 120px;
}

.header-content {
  padding: 100px;
}

.homepage-header .logo {
  width: 80%;
  min-width: 350px;
  max-width: 640px;
}


.homepage-title {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
}

.phones {
  width: 100vw;
  margin-top: -120px;
}

.homepage-video {
  height: 800px;
}

.video-container {
  border: 1px solid rgb(43, 43, 43);
  border-radius: 24px;
  width: 66%;
  min-width: 300px;
  margin-top: 30px;
}

.homepage-video {
  width: 100%;
  height: auto;
  border: none;
}

.gradient-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(./parallax.png);
  background-size: cover;
  background-size: 200vw;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -100px;
  width: 100vw;
  height: 2px;
  overflow: hidden;
}

.highlight {
  /* Sets the initial color to red and end color to blue */
 /* background: linear-gradient(to bottom, red, blue);  */
  /* Clips the background to the text */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  /* Makes the text transparent so the background shows through */
  color: transparent;
}

.icon-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 60px 0;
  width: 500px;
}

.icon-section h3 {
  font-weight: 600;
  font-size: 24px;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.about-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
}

.about-row {
  display: flex;
  flex-direction: column;
}

.column {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.column-image {
  width: 100%;
  max-width: 300px;
  height: 600px;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.column-headline {
  font-size: 24px;
  margin-top: 20px;
}

.column-text {
  margin-top: 10px;
  color: #888;
  width: 300px;
  font-size: 18px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./background.png);
  background-size: cover;
  padding-bottom: 70px;

}

.submit-button{
  width: 100%;
  padding: 15px 40px;
  background-color: #a546fc;
  color: #fff;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px; 
  margin-top: 20px;
}

.form-title {
font-size: 48px;
margin: 0px;
}

.form-desc {
  font-size: 18px;
  color: gray;
  font-weight: normal;
  }

.form-group button {
  width: 100%;
}

.row .column {
  text-align: left;
}

.about-row .column {
  text-align: left;
}

.about-row-2 .column {
  text-align: left;
}

.icon-section .fa-solid {
  color: #a546fc;
  font-size: 36px;
}

.features-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.features-section .column {
  flex: 1;
  text-align: left;
  padding: 40px;
  width: 100%;

}

.features-section .column-image {
  width: 100%;
  max-width: 800px;
  height: 500px;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in 1s ease-in-out forwards;
}

.features-section h3 {
  font-size: 42px;
  margin-bottom: 20px;
  font-weight: 600;
}

.features-section .column-text {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  animation: fade-in-up 1s ease-in-out forwards;
}

.features-list {
  list-style: none; /* removes bullet points */
  padding-left: 0; /* removes default padding */
}

.feature-item {
  display: flex; /* aligns icon and text horizontally */
  align-items: center; /* centers items vertically */
  margin-bottom: 0px; /* space between items */
}

.feature-item .fa-solid {
  margin-right: 10px; /* space between icon and text */
  font-size: 20px; /* size of the icon */
  width: 24px;
}

.footer-container {
  background-color: #000000;
  width: 100%;
  border-top: 1px solid #121212; 
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 60px;
  background-color: #000000;
  width: 100%;
  margin: auto;
  border-top: 1px solid #121212; 
}

.footer h3 {
  font-size: 24px;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
}

.footer-logo {
  width: 250px;
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-links a {
  font-size: 32px;
  color: #fff;
  text-decoration: none;
  padding: 20px;
}

.footer-contact-form input,
.footer-contact-form textarea {
  width: 100% !important;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 0 0 30px #121212 inset;
  background-color: #121212;
  border: none;
  border-radius: 9px;
  font-size: 16px;
}

.footer-contact-form textarea {
  border-radius: 12px;
  min-height: 100px;
  margin-top: 10px;
  margin: 5px;
  color: white;
}

.footer-contact-form button {
  background-color: #a546fc;
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  margin: 5px;
}

@media only screen and (max-width: 1024px) {

  .footer {
    padding: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .navbar {
    width: 90%;
    margin-top: -30px;
  }
  .video-container {
    width: 90%;
  }

  .icon-section {
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;

  }

  .homepage-header {
    background-size: 280vh;
  }

  .header-content {
    padding: 100px 0px;
    overflow: hidden;
  }

  .features-section {
    flex-direction: column;
  }

  .features-image {
    width: 100% !important;
    align-self: center;
  }

  .about-section {
    flex-direction: column;
  }

  .about-section img {
    height: 400px;
  }


  .about-row {
    display: flex; 
    flex-direction: row;
  }

  .about-row-2 {
    display: flex;
  }

  .about-row-2 .column{
    text-align: right;
  }
  
  .footer {
    flex-direction: column;
    width: 100%;
    align-items: left;
  }

  .footer img {
    align-self: center;
  }
}

@media only screen and (max-width: 768px) {
  .homepage-header h1{
    font-size: 24px;
    padding: 0px 20px;
  }
  .homepage-header h2{
    font-size: 18px;
    padding: 0px 20px;
  }

  .homepage-header {
    background-size: 200vh;
  }

  .message {
    font-size: 24px;
    padding: 20px 0px;
    width: 90%;
  }

  .about-row {
    display: flex; 
    flex-direction: column;
  }

  .about-row-2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-row-2 .column{
    text-align: left;
  }

  .form-title {
    font-size: 36px;
    margin: 0px;
    }
    
    .form-desc {
      font-size: 16px;
      color: gray;
      font-weight: normal;
      }
}

/* Organization Search Page */

.org-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #121212;
  border-radius: 12px;
  cursor: pointer;
}

.org-item:hover {
  background-color: #a446fc0e;
}

.org-item img {
  height: 50px;
  margin-right: 10px;
}

.org-container {
  z-index: 99;
  width: 30%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  background-color: #0d0d0d;
}

.org-container input {
  margin: 20px;
  width: 100%;
  max-width: 400px;
}

.org-background {
  width: 100%;
  background-image: url(./campus.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  display: flex;
  flex-direction: column;
}

/* Organizers Page */

.org-search-bar {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  border-radius: 12px;
  padding-left: 15px;
  overflow: hidden;
}

.org-search-bar .fa-search {
  color: gray;
}

.fa-search {
  z-index: 1;
}

.org-search-bar input {
  flex-grow: 1;
  padding: 10px;
  border: 0px !important;
  box-shadow: 0 0 0 30px #1c1c1c inset !important;
}

.org-search-bar input:active {
  border: none !important;
}

.org-search-bar input:focus {
  border: none !important;
}

.organizers-page {
  position: relative;
  background-color: black;
  color: white;
  column-gap: 40px;
  margin-top: 75px;
}

.organizers-content {
  width: 100%;
  max-width: 600px;
}

.organizers-list {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
}

.organizer-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Content is aligned to the left */
  margin-bottom: 1rem;
  border: 1px solid #494949;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.5s;
  width: 100%; /* Make the li element take the full available width */
}

.organizer-name {
  flex-grow: 1; /* Take up all available space */
  margin: 0 10px; /* Optional: Give it some margin for spacing */
}


/* Hover effect for the organizer link */
.organizer-item:hover {
  cursor: pointer; /* Changes the cursor to a hand, indicating a clickable area */
  background-color: #121212; /* Changes the background color to dark gray when hovering */
  border: 1px solid var(--accent-color);
  transition: all 0.5s;
}

.organizer-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.organizer-name {
  font-size: 16px;
}

/* This is to reset the color and remove underlines from the link */
.organizer-item, .organizer-item:visited {
  color: inherit; /* Inherits the color from its parent (or any defined color in a higher specificity) */
  text-decoration: none;
  display: flex; /* Using flexbox for alignment */
  align-items: center; /* Vertically center-aligns the content */
}

/* Keeps the organizer name's color unchanged */
.organizer-name {
  margin-left: 10px; /* Adds some space between the image and the name */
}

/* Override the default link color and underline */
.organizer-link, .organizer-link:visited {
  color: inherit; /* Inherits the color from its parent or any defined color in higher specificity */
  text-decoration: none; /* Removes underline */
  display: flex;
  align-items: center;
}

/* Add a hover effect for the link */
.organizer-link:hover {
  color: inherit; /* Keeps the color unchanged during hover */
}


/* Privacy Page */

.Privacy-Page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh; /* This will make the container take up the full height of the viewport */
  padding-left: 200px;
  padding-top: 50px;
  width: 1000px;
}

@media only screen and (max-width: 1024px) {
  .Privacy-Page{
    width: 90%;
    padding-left: 20px;
    padding-top: 30px;
  }
}

/* Connection Button */

.connection-confirmation {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;            /* Add this to center horizontally */
  transform: translate(-50%, -50%); /* Shifts the element's top-left corner to the center of the screen */
  overflow: hidden;
  background-color: #121212;
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width:300px;
}

.connection-confirmation button {
  margin-top: 10px;
}

.connection-confirmation p {
  text-align: center;
}


/* Base styles for the button */
.connection-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0px 20px;
}

/* Styles when the connection is established */
.connected {
  background-color: var(--accent-color);
  color: white;
}

/* Styles when the user can send a request or when the request is pending */
.connect, 
.requested, .accept {
  background-color: #121212;
  color: var(--accent-color);
}

/* If you want to add hover effects: */
.connection-button:hover {
  background-color: var(--accent-color);
  color: white;
}

.connected:hover {
  background-color: darkmagenta;  /* a darker shade of purple for hover effect */
}

.attendee-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
.delete-button {
  height: 25px;
  width: 25px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  padding-left: 8.5px;
  margin-left: -10px;
  color: #a546fc;
} 

.delete-button:hover {
  background-color: #d32f2f; /* Darker red on hover 
  color: white;
} */

/* If you want a subtle shadow for the buttons */
.connection-button, .delete-button {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Layout */

.layout-container {
  overflow-x: hidden;
}

.layout-content {
  margin-left: 350px;
  margin-top: 100px;
  margin-right: 100px;
}

@media only screen and (max-width: 1024px) {

.layout-content {
  margin-left: 20px;
  margin-top: 100px;
  margin-right: 20px;
}

}

/* User Page */

.user-page-name {
  display: inline-block; /* Ensures the width of the element is just enough to fit the content */
  margin-right: 10px; /* Add some space between the name and the buttons */
  max-width: 300px;
}


.message-input-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  background-color: #242424;
  border-radius: 50px;
  position: fixed;
  bottom: 50px;
  width: 90%;
  max-width:700px;
  z-index: 999;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 1);
}

.message-input-container input[type="text"] {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border-radius: 24px;
  outline: none;
}

.message-input-container button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.message-input-container button:hover {
  background-color: white;
  color: var(--accent-color)
}


.user-stats {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px !important; /* Adjust the font size as needed */
}

.user-stats button {
  border: none;
  background: none;
  color: inherit; /* Keep the color consistent with the surrounding text */
  cursor: pointer;
  padding: 0;
  font-size: 20px !important;
}

.user-stats button:hover {
  border: none;
  background: none;
}

.stat-divider {
  color: gray;
  font-size: 28px;
}


/* Container for the pinned circles section */
.pinned-circles {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Title for the pinned circles section */
.pinned-circles h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

/* List of pinned circles */
.pinned-circles ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  
}

/* Individual circle item */
.pinned-circles .circle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

/* Hover effect for circle item */
.pinned-circles .circle-item:hover {
  background-color: #121212;
}

/* Image for the circle */
.pinned-circles .circle-item img {
  border-radius: 50%;
  margin-bottom: 15px;
}

/* Name of the circle */
.pinned-circles .circle-item span {
  font-size: 1.1rem;
}

/* Link styling */
.pinned-circles a {
  text-decoration: none;
  color: inherit;
}


.no-content {
  color: gray;
  padding: 50px;
  text-align: center;
}

/* Overall styles for the user profile container */
.user-profile {
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Styles for the user's name heading */
.user-profile h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

/* Styles for the links wrapping the posts, assuming you might want to remove underlines for them */
.user-profile a {
  text-decoration: none;
  color: inherit;
}

.profile-tabs {
  display: flex;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
}

.profile-tabs button {
  padding: 10px 20px;
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: border-color 0.3s;
  color: white;
  border-radius: 0px;
}

.profile-tabs button:hover {
  background-color: #121212;
}

.profile-tabs button.active {
  border-bottom-color: var(--accent-color);
}

.memory img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.polaroid {
  margin: 10px;
  margin-top: 20px;
  max-width: 400px;
  background-color: #181818;
  padding: 20px 20px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;  /* for horizontal alignment */
  vertical-align: top;  /* keeps the Polaroids aligned at the top */
}

.polaroid img {
  width: 400px; /* Define a fixed width */
  height: 400px; /* Same value as width to make it a square */
  object-fit: cover; /* This will crop the image to fit the container without stretching */
  display: block; /* To remove any default margins/padding */
  border-radius: 5px;
}

.memory-info {
  margin-top: 10px;
}

.user-profile .user-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 70px !important;  /* adjust as needed */
  height: 70px !important;
  border-radius: 50%;  /* makes the image circular */
  margin-right: 20px;  /* spacing between image and name */
  object-fit: cover;
}

.user-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-role {
  color: gray;
}

.user-name h1 {
margin: 0px;
}

.send-message {
background-color: #a546fc;
color: white;
border-radius: 9px;
}

.bio {
  color: gray;
  margin-top: 0px;
}

@media only screen and (max-width: 1024px) {
  .polaroid {
    margin: 0px;
    margin-top: 20px;
  }
  .user-name h1 {
    font-size: 20px;
  }

  .column-on-mobile {
    flex-direction: column;
  }

  .user-stats {
    margin-top: 20px;
  }
}

/* PostDeetsModal styling */

.custom-checkbox {
  display: flex;
  align-items: center;
  background-color: #292929;
  border: 1px solid #454545;
}

.checkbox-label {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}

/* When the checkbox is checked, add a "checked" appearance */
.checkbox-label:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 6px;
  border-left: 2px solid #4CAF50;
  border-bottom: 2px solid #4CAF50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  opacity: 0;
}

/* Use the :checked pseudo-class to change the after element's opacity */
input[type="checkbox"]:checked + .checkbox-label:after {
  opacity: 1;
}


.overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Black with 70% opacity */
  z-index: 10; /* This ensures that the overlay is below your modal but above everything else */
}


.PostDeetsModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1000px;
  background-color: #121212;
  padding: 30px;
  border-radius: 24px;
  box-shadow: 0 5px 15px rgba(63, 63, 63, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.PostDeetsModal {
  max-height: 80vh; /* This makes sure the modal takes up a maximum of 80% of the viewport height */
  overflow-y: auto; /* This ensures content is scrollable vertically when it overflows */
  overflow-x: hidden;
  padding: 40px;   /* Adjust if necessary for spacing within the modal */
}

.close-post-button {
  padding: 0px 0px;
  position: absolute;
  background: none !important;
  align-self: end !important;
  font-size: 36px !important;
  margin-bottom: -50px;
  top: 0px;
  right: 10px;
  width: 50px !important;
}

.PostDeetsModal .divider {
  height: 1px;               /* Defines the thickness of the divider */
  background-color: #ddd;   /* Defines the color of the divider */
  margin: 15px 0;           /* Provides some spacing around the divider */
  width: 100%;              /* Ensures the divider stretches the full width */
}

.privacy-mode {
  width: 200px !important;
}

/* Privacy Section */
.PostDeetsModal .privacy-section {
    margin-bottom: 0px;
    width: 100%;
}

.post-type-section {
  max-width: 300px;
  margin-bottom: 30px;
}

.post-type-select, .PostDeetsModal select, .edit-post-section select {
    width: 100%;
    padding: 10px 15px;;
    margin-top: 10px;
    border: none;
    border-radius: 12px;
    appearance: none;
    background: #242424;
    color: white;
    margin-left: 0px;
    font-size: 12px !important;
    outline: none;
}



/* Search User Styles */
.PostDeetsModal input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #191919;
    border-radius: 5px;
}

/* Style the calendar icon for WebKit browsers */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);  /* This will invert the colors, turning black into white and vice versa */
}

/* For good measure, you might also want to ensure that the color of the input text is set to white */
input[type="datetime-local"] {
  color: white;
}

.search-container {
  position: relative;
  border: 1px solid #121212 !important;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #121212;
  border-radius: 4px;
  position: relative;
}

.search-type {
  border: none;
  background-color: transparent;
  padding: 8px 12px;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-type {
  flex: 1; /* Let it take available space before the input */
  appearance: none; /* Remove default browser styles */
  -webkit-appearance: none; /* Remove default browser styles for Webkit browsers */
  border: 1px solid #ccc; /* Example border, adjust as needed */
  padding-right: 25px; /* Space for the arrow, adjust as needed */
  position: relative; /* To position the arrow */
}

.search-type::after {
  content: '\25BC'; /* Downward arrow character */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%); /* Centers vertically */
  pointer-events: none; /* Make it non-interactive */
  color: #FFFFFF;
}

.search-input {
  border: none;
  padding: 8px 12px;
  flex-grow: 1;
  outline: none;
}

.search-input {
  flex: 2; /* Let it take double the available space after the select */
  border: 1px solid #ccc; /* Example border, adjust as needed */
  margin-left: 10px; /* Gap between select and input */
}

.floating-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #121212;
  border: 1px solid #191919 !important;
  border-radius: 24px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px; /* This makes it appear as a part of the search bar */
}

.floating-search-results ul {
  border: 1px solid #191919 !important;
}

.add-question-btn {
  font-size: 12px !important;
}

.privacy-section {
  margin-bottom: 20px;
}

.privacy-options,
.privacy-sub-options {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.privacy-option {
  padding: 8px 16px;
  border: none;
  border-radius: 12px !important;
  background-color: #242424;
  cursor: pointer;
  font-size: 12px !important;
}

.privacy-option.active {
  background-color: var(--accent-color);
  color: white;
}

.privacy-option:hover {
  background-color: var(--accent-color) !important;
}

.open-to-public-option {
  margin-top: 20px;
  margin-bottom: 20px;
}

.visible-to-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Provides spacing between items */
  list-style: none; /* Removes bullets from list */
  padding: 0px 0px !important; /* Removes default padding */
  border: none !important;
  width: 100% !important;
}

.visible-to-list .visible-to-item {
  display: flex;
  flex-direction: row; /* Allows vertical stacking of image and text */
  position: relative; /* Allows the absolute positioning of the close button */
 /* width: calc(25% - 16px); /* Takes 1/3 of the container width minus the gap. Adjust as required */
  background-color: #202020; /* Background color for better visual differentiation */
  border-radius: 4px; /* Makes items rounded */
  overflow: hidden; /* Keeps inner content within the boundary */
  transition: box-shadow 0.3s; /* Smooth transition for hover effect */
  align-items: center;
  padding: 10px !important;
}

.visible-to-item span {
  font-size: 12px;
}

.visible-to-list li:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow effect on hover */
}

.visible-to-list .visible-to-item img {
  width: 25px; /* Makes image take full width of the list item */
  height: 23px; /* Maintains aspect ratio */
}

.remove-item-button {
  background-color: #484848; /* Semi-transparent black */
  color: #fff; /* White text color */
  border: none;
  border-radius: 100px; /* Makes button circular */
  width: 24px !important; /* Adjust width to make it circular */
  height: 24px; /* Adjust height to match width */
  font-size: 12px;
  padding: 0 !important; /* Remove padding to avoid affecting the circle shape */
  margin-left: 10px !important;
  margin-top: 0px !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.remove-item-button:hover {
  background-color: lightcoral !important; /* Darker color on hover */
}


.remove-button {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: #fff; /* White text color */
  border: none;
  border-radius: 50%; /* Makes button circular */
  width: 12px;
  height: 12px;
  margin: 0px !important;
  font-size: 16px;
  padding: 8px !important;
  margin-left: 10px !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.remove-button:hover {
  background-color: lightcoral !important; /* Darker color on hover */
}


/* Shadow for distinction */
.floating-search-results {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.PostDeetsModal ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px; /* adjust based on your preference */
    overflow-y: auto;
}

/* searching users */
.PostDeetsModal li {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #202020; /* subtle lines between users */
}

.PostDeetsModal li:last-child {
    border-bottom: none;
}

.PostDeetsModal li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

/* Hover effect for user items */
.PostDeetsModal li:hover {
    background-color: #202020;
}

.PostDeetsModal h1 {
  margin: 0px;
}

.PostDeetsModal h2 {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 10px;
}

.textarea-container {
  position: relative;
  width: 100%;
}

.title-input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  min-height: 40px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide the scrollbar */
  font-size: 48px;
  max-width: 900px;
  outline: none;
  font-weight: bold;
}

.title-input.placeholder:before {
  content: attr(data-placeholder);
  color: #888;
  position: absolute;
  pointer-events: none;
}

.hidden-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid transparent; /* Invisible border for padding calculation */
  white-space: pre-wrap; /* Ensure white spaces are preserved */
  word-wrap: break-word; /* Break long words */
  visibility: hidden; /* Hide the element */
  pointer-events: none; /* Prevent interaction */
}


.PostDeetsModal .content-textarea {
  font-size: 18px;
}

.PostDeetsModal input[type="text"],
.PostDeetsModal input[type="date"],
.PostDeetsModal input[type="number"],
.PostDeetsModal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none !important;
  border-radius: 5px;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 90px #121212 inset !important;
}

.PostDeetsModal input[type="text"]:hover,
.PostDeetsModal input[type="date"]:hover,
.PostDeetsModal input[type="number"]:hover,
.PostDeetsModal textarea:hover {
  border: none !important;
}



.PostDeetsModal input[type="text"]:focus,
.PostDeetsModal input[type="date"]:focus,
.PostDeetsModal input[type="number"]:focus,
.PostDeetsModal textarea:focus {
  border: none !important;
}

.PostDeetsModal textarea {
  resize: none;
  outline: none !important;
}

.PostDeetsModal label {
  font-weight: 600;
  margin-right: 10px;
}

.date-section {
  padding: 10px;
}

.PostDeetsModal button {
  border: none;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.PostDeetsModal button:hover {
  background-color: #ddd;
}

.PostDeetsModal h3 {
margin-bottom: 10px;
}

.PostDeetsModal h4 {
  margin-bottom: 10px;
  }

.PostDeetsModal .input-container {
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 15px;
  flex-direction: row;
}

.PostDeetsModal .ending-time-btn {
  font-size: 16px;
  padding: 0px;
  background: none;
  color: var(--accent-color);
}

.PostDeetsModal .ending-time-btn:hover {
  font-size: 16px;
  padding: 0px;
  background: none;
  color: #ba71ff;
}

.PostDeetsModal .image-label {
  flex-shrink: 0;
  cursor: pointer;
  width: 200px;
  height: 200px;
  position: relative;
}

.PostDeetsModal .image-input {
  display: none;
}

.PostDeetsModal .uploaded-preview {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.PostDeetsModal .cover-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191919;
  color: gray;
}

.PostDeetsModal .icon {
  cursor: pointer;
  margin-right: 10px;
}

.remove-button-txt {
background: none !important;
margin: 0px !important;
}

.PostDeetsModal .text-inputs {
  flex: 1;
}

.PostDeetsModal .title-input, 
.PostDeetsModal .content-textarea {
  width: 100%;
}

.PostDeetsModal .content-textarea {
  margin-top: 10px;
}

.create-button {
  align-self: center;
  background-color: var(--accent-color) !important;
}

.cancel-button {
  background: none !important;
  color: var(--accent-color) !important;
  align-self: center;
  border: none;
  cursor: pointer;
}

.cancel-button:hover {
  background: none !important;
  color: white !important;
  align-self: center;
  border: none;
  cursor: pointer;
}


/* Edit Deets */

.image-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.image-preview-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
}

.uploaded-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.change-image-button {
  position: relative;
  width: 100%;
  max-width: 300px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 20px;
}

.change-image-button:hover {
  background-color: #484848;
}

.new-image-preview {
  display: flex;
  flex-direction: column;
}

.revert-button {
  background: none;
  border: none;
  width: 250px;
  margin: 20px;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 16px;
}

.upload-placeholder .icon {
  font-size: 48px;
  margin-bottom: 5px;
}

.image-input {
  display: none;
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #ff0000;
}

.remove-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.edit-post-section {
  
}

.edit-post-section input, .edit-post-section textarea{
  -webkit-box-shadow: 0 0 0 90px #242424 inset !important;
}

.edit-deets-buttons {
  margin: 50px 0px;
}

.edit-deets-buttons .save-button {
  background-color: #a546fc;
  color: white;
  border: none;
  padding: 10px 15px;
}

.edit-deets-buttons button{
  margin-right: 20px;
  font-size: 16px;
}

/* General Organizer Dashboard Styles */

.qr-code-buttons {
  margin: 15px;
}

.qr-code-buttons button{
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.organizer-dashboard {
  display: flex;
  width: 100%;
  background-color: #000;
  color: #e6e6e6; /* Light gray text for better visibility on dark backgrounds */
  margin-top: 60px;
}

/* Sidebar Styles */

.sidebar-dashboard {
  width: 300px;
  border-right: 1px solid #333; /* Adjusted for dark theme */
  padding: 20px;
  background-color: #121212;
  min-height: 100vh;
  padding-right: 0px;
  position: relative;
}

.post-info {
  margin-bottom: 20px;
  padding-right: 20px;
}

.post-info img {
  width: 100%; /* Adjust as needed */
  height: auto; 
  border-radius: 8px;
  margin-bottom: 10px;
}

.post-info h3 {
  font-size: 18px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  display: wrap;
}

.vertical-tabs,
.horizontal-tabs {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.horizontal-tabs {
  flex-direction: row; /* Aligns the buttons horizontally */
  width: 100%;
}

.vertical-tabs button,
.horizontal-tabs button {
  background: none;
  border: none;
  padding: 15px 10px;
  margin: 5px 0px;
  margin-right: 0px;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s;
  color: #e6e6e6; /* Light gray text for better visibility on dark backgrounds */
  font-size: 16px;
}

.horizontal-tabs button {
  width: 100%;
  max-width: 150px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 0px;
}

.selected-vertical-tab {
  border-left: 4px solid var(--accent-color) !important;
  background-color: #181818 !important; /* Light purple background */
  font-weight: bold; /* Optional: makes the text bold for selected tab */
  padding-left: 20px !important;
}

.selected-horizontal-tab {
  border-bottom: 4px solid var(--accent-color) !important;
  font-weight: bold; /* Optional: makes the text bold for selected tab */
}

.content-section .divider {
  margin-top: 0px;
}

.vertical-tabs button:last-child {
  border-bottom: none;
}

.vertical-tabs button:hover,
.horizontal-tabs button:hover {
  background: #222; /* Slightly brighter than the main background for the hover effect */
}

.dropdown-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 18px;
  padding: 10px;
}

.org-toggle {
  position: relative;
  top: 80px !important;
  text-align: center;
  margin: auto;
}

.sidebar-dashboard {
  display: flex;
  flex-direction: column;
}

.sidebar-dashboard.open {
  display: flex;
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {

  .edit-post-section {
    padding: 0px;
  }

}

@media (max-width: 1024px) {


  .dropdown-toggle {
    display: block;
    z-index: 0;
    margin-top: 30px;
    transition: all 0.2s;
  }

  .dropdown-toggle:hover {
    background:none;
    color: #a546fc;
  }

  .vertical-tabs {
    margin-top: 0px;
  }

  .sidebar-dashboard {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    background-color: #121212;
    z-index: 0;
    border-bottom: 1px solid #484848;
  }

  .sidebar-dashboard.open {
    position: relative;
    padding-top: 30px;
    min-height: fit-content;
    margin-top: 30px;
  }

  .vertical-tabs button {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .content-section {
    padding: 10px;
    margin: 0px !important;
    width: 100% !important;
  }

  .organizer-dashboard {
    display: flex;
    flex-direction: column;
  }
}

/* Content Section Styles */

.content-section {
  position: relative;
  display: block;
  flex: 1;
  padding: 50px;
  width: 80%;
  margin: 0px 0px 0px auto;
}

.custom-fields-section {
  padding: 20px;
}

.qr-code-section h3,
.attendees-section h2,
.custom-fields-section h2 {
  font-size: 24px;
  margin-top: 0;
}

.divider {
  border: none;
  border-top: 1px solid #333; /* Adjusted for dark theme */
  margin: 20px 0;
}

/* Inputs and Buttons Style */


button {
  background: #222;
  color: #e6e6e6;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 5px 10px;
}

button:hover {
  background: #444;
}

.attendees-section table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}

td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}

td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}

.attendees-section th, .attendees-section td {
 /* border-bottom: 0.5px solid #202020;  Light gray border */
  padding: 8px 12px;
  text-align: left;
}

.attendees-section th {
  background-color: #000000;
  padding: 15px 20px; /* Light gray background for header */
}

.attendees-section tr {
  background-color: #0d0d0d;
  border-radius: 82px;
}

.actions-top-right {
  display: flex;
  justify-content: flex-end;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-justify-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-field-btn {
  background-color: #121212;
  color: #a546fc;
  padding: 10px 20px;
  border:none;
  margin: 5px;
  font-size: 16px;
}

.undo-changes-btn {
  border: none;
  background: none;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 5px;
}

.undo-button {
  background: none;
  border: none;
}

.save-changes-btn {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  background-color: var(--accent-color);
  border: none;
}

.save-changes-btn:hover {
  color: var(--accent-color);
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  border: none;
}

.custom-fields-section p {
  color: gray;
}

.attendees-section h2 {
  margin: 0px;
}

/* Billing Overview */

.no-default {
  color: gray;
}

.billing-overview button {
  margin-right: 10px;
}

/* Hide the default checkbox */
.custom-checkbox input {
  display: none;
}

/* Style the label */
.custom-checkbox label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}

/* Create a custom box */
.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked + label:before {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox label:after {
  content: '';
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.custom-checkbox input:checked + label:after {
  display: block;
}


.payment-methods {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 30px;
}

.payment-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.payment-history-table th,
.payment-history-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.payment-history-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.payment-history-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.payment-history-table tbody tr:hover {
  background-color: #eaeaea;
}

/* Responsive table */
@media (max-width: 768px) {
  .payment-history-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}


.balance-cards {
  display: flex;
  flex-direction: row;
}

.balance-card {
  background-color: #121212;
  padding: 30px;
  border-radius: 24px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.balance-card p {
  margin: 0px;
  margin-bottom: 10px;
  color: gray;
}

.balance-card h2 {
  margin-top: 0px;
}

.billing-estimate {
  max-width: 350px;
}

.billing-estimate input {
  width: 100% !important;
  margin-bottom: 20px;
}

.card-heading {
  color: white !important;
  margin-top: 0px;
}

.balance p {
  color: gray;
}

.balance-price {
  font-size: 56px;
  font-weight: bold;
  margin: 0px;
}

.payment-method {
  background-color: #121212;
  padding: 30px;
  border-radius: 24px;
}

.your-plan {
  background-color: #121212;
  padding: 30px;
  border-radius: 24px;
  margin-bottom: 20px;
}

.payment-method h2 {
  margin-top: 0;
}

.payment-method button {
  margin-top: 15px;
}

.card-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.25px solid gray;
  border-radius: 12px;
  padding: 0px 10px;
  width: 400px;
}

.card-info img {
  margin-right: 15px;
}

.card-info h4 {
  margin-right: 10px;
}

.card-info h5 {
  margin-right: 10px;
}

.card-info p {
  color: gray;
}

.card-brand-logo {
  width: 20px;
}

/* Add more styles as necessary */


.card-element {
  color: white !important;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
}


/* Organization Dashboard */

.editable-container {
  display: flex;
  align-items: center;
}

.editable-container input {
  display: none;
  margin-left: 10px;
}

.editable-container .pencil-icon {
  cursor: pointer;
  margin-left: 10px;
}

.editable-container.editing input {
  display: block;
}

.editable-container.editing .org-name-display {
  display: none;
}

.editable-container .org-name-display {
  font-size: 16px;
}


.switch {
  position: relative;
  display: inline-block;
  min-width: 60px;
  height: 34px;
  max-width: 60px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .toggle {
  background-color: var(--accent-color);
}

input:checked + .toggle:before {
  transform: translateX(26px);
}

.toggle.round {
  border-radius: 34px;
}

.toggle.round:before {
  border-radius: 50%;
}



@media (max-width: 768px) {
  .content-section {
    padding: 20px
  }
}

.schedule-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
}

.schedule-time .react-datepicker-wrapper {
  width: 100%;
}

.schedule-time input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.12);
  margin-top: 10px;
}

.schedule-time input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.schedule-time .react-datepicker__input-container {
  width: 100%;
}

.schedule-time .react-datepicker {
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.schedule-time .react-datepicker__header {
  background-color: #007bff;
  border-bottom: 1px solid #007bff;
}

.schedule-time .react-datepicker__current-month,
.schedule-time .react-datepicker-time__header {
  color: #fff;
}

.schedule-time .react-datepicker__day--selected, 
.schedule-time .react-datepicker__day--keyboard-selected {
  background-color: #007bff;
}

.schedule-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #007bff;
  color: #fff;
}


.email-content-view {
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.email-content-view h3 {
  margin-top: 0;
}

.email-content-view button {
  margin-top: 20px;
}

.change {
  font-weight: light;
  font-size: 14px;
}
.increase {
  color: green;
}
.decrease {
  color: darkred;
}


.date-range-controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-range-controls label {
  margin-right: 10px;
}

.date-range-controls select,
.date-range-controls input {
  margin-right: 20px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #121212;
  background: #242424;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
}

.custom-date-range {
  display: flex;
  align-items: center;
}

.custom-date-range label {
  margin-right: 10px;
}


.count-per-page {
  width: 100%;
  justify-content: right;
  align-items: right;
  text-align: right;
}

.dropdown-select {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.dropdown-select select {
  margin-left: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #242424;
  color: white;
  padding: 5px 5px;
}

.member-search {
  width: 100% !important;
  max-width: none !important;
  margin: 0px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  list-style: none;
}

.page-item {
  margin: 0 0.25rem;
}

.page-item a {
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.page-item a:hover {
  background-color: #007bff;
  color: white;
}

.page-item.active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-item.disabled a {
  color: #ddd;
  pointer-events: none;
  border-color: #ddd;
}

.page-item a:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.invite-link-box {
  display: flex;
  align-items: center;
  background-color: #222222;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #484848;
  margin-top: 10px;
  color: gray;
  max-width: fit-content;
}

.invite-link-box span {
  flex: 1;
  word-break: break-all;
}

.copy-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.copied-message {
  position: absolute;
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
}

.container-section {
  margin: 20px 0px;
}

.dash-container {
  padding: 40px;
  background-color: #121212;
}

.email-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 500px;
}

.email-input-container input{
margin: 20px 0px;
margin-top: 10px;
}

.email-box {
  display: flex;
  align-items: center;
  background-color: #242424;
  padding: 5px 15px;
  border-radius: 9px;
}

.email-box button {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding-right: 0px;
}

.email-input-container input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  flex-grow: 1;
  min-width: 200px;
}


.all-users-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust as needed for spacing */
  padding: 10px;
  border: 1px solid gray;
  border-radius: 12px;
  margin-top: 10px;
}

.all-user-email, .remaining-emails {
  padding: 4px;
  background-color: #121212;
  border-radius: 4px;
  white-space: nowrap; /* Prevents email text from wrapping */
}


.member-info {
  display: flex;
  align-items: center;  /* this will center items vertically */
  gap: 10px;           /* this will add a gap between the image and the name */
}


.ellipsis {
  padding: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;  /* Adjusted to the right */
  background-color: #191919;
  min-width: 160px;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 5px;
}

.dropdown-content a {
  color: gray;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #212121;
  border-radius: 9px;
  margin: 0px;
}

.dropdown:hover .dropdown-content {
  display: block;
}


.members-container {
  padding: 15px;
  background-color: #121212;
  border-radius: 10px;
  margin-top: 20px;
}

.members-table {
  width: 100%;
  border-collapse: collapse;
}

.members-table th, .members-table td {
  padding: 8px 12px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
}

.members-table th, .members-table td {
  padding: 10px;
  text-align: left;
 /* border-bottom: 1px solid #ddd; */
 border-bottom: 1px solid #191919;
}

.members-table td {
  color: gray;
  border-bottom: 1px solid #191919;
}

.members-table th {
 /* background-color: #f2f2f2; */
}

.member-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}



.radio-container {
  margin-bottom: 20px;
}

.radio-container label {
  display: inline-block;
  margin: 8px 10px 8px 0;
  cursor: pointer;
}

.radio-container input[type="radio"] {
  margin-right: 5px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.radio-container input[type="radio"]:checked + label {
  font-weight: 600;  /* Make the label text bold for selected option */
  color: #007BFF;     /* Change color of the label text for selected option */
  border: 1px solid #007BFF;  /* Optional: Add a border around the selected label */
  padding: 5px;
  border-radius: 5px; /* Optional: To give the border rounded corners */
}

.radio-container label {
  display: inline-block;
  margin: 8px 10px 8px 0;
  cursor: pointer;
  padding: 5px; /* Padding for label */
  border-radius: 5px; /* Rounded corners for label */
  transition: all 0.3s; /* Smooth transition effect */
}

.radio-container input[type="radio"] {
  display: none;  /* Hide the default radio buttons */
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.status-active {
  background-color: green;
}

.status-disabled {
  background-color: red;
}

.status-change-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* This ensures the popup stays on top of other content */
  text-align: center;
}

.status-change-popup button {
  margin: 10px;
  padding: 5px 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.status-change-popup button:hover {
  background-color: #0056b3;
}

.status-change-popup button:last-child {
  background-color: #dc3545;
}

.status-change-popup button:last-child:hover {
  background-color: #c82333;
}

.blast-emails-container {
 /* border: 1px solid gray; */
  border-radius: 24px;
  padding: 45px;
  margin: 20px 0px;
  background-color: #121212;
}

.table-container {
  max-width: 100%;
  padding: 20px;
  padding-bottom: 90px;
  overflow-x:auto;
  border: 0.25px solid #242424;
  border-radius: 12px;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.4), inset -10px 0 8px -8px rgba(0, 0, 0, 0.4); /* Inside shadows */
}


.blast-emails-container h2 {
margin-top: 0px !important;
margin-bottom: 20px !important;
 }

.blast-emails {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.blast-emails th, .blast-emails td {
  padding: 10px;
  text-align: left;
}

.blast-emails td {
  color: gray;
}

/* If you want borders between rows */
.blast-emails tr {
  border-bottom: 1px solid #e0e0e0;
}

/* Hide default radio button */
/* Hide default radio button */
input[type="radio"] {
  display: none;
}

/* Styling the label to have a cursor pointer */
.blast-email-section label {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px; /* Spacing between labels */
  position: relative; /* Make the label a positioning context */
}

/* Create a custom radio button using ::before */
.blast-email-section label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #555;  /* Color of the custom radio border */
  margin-right: 8px;       /* Space between the custom radio and the label text */
  vertical-align: middle;
}

/* Show the radio is selected using ::after */
input[type="radio"]:checked + label::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;  /* Color of the selected dot */
  top: 50%;                /* Center the dot vertically */
  left: 5.5px;               /* Adjust as needed to center it horizontally */
  transform: translateY(-50%); /* To perfectly center it vertically */
}

.email-preview-box {
  overflow-y: auto; 
  border: 1px solid gray; 
  border-radius: 12px;
  padding: 30px; 
  max-height: 800px; 
  min-width: 350px;
  width: 100%;
  box-sizing: border-box; 
  background-color: white;
  border-left: solid 1px gray;
}

.branding-container {
  max-width: 900px;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  background-color: #121212;
}

.branding-container p {
  color: gray;
}

.branding-container .image-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.branding-container .image-input {
  display: none;
}

.branding-container .uploaded-preview {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  object-fit: contain;
  border: 0.5px solid gray;
}

.branding-container .cover-image {
  width: 200px;
  height: 200px;
  border: 2px dashed gray;
  display: flex;
  align-items: center;
  justify-content: center;

}

.branding-container .remove-button-txt {
  margin-top: 20px !important;
  width: 200px;
}

.branding-field {
  margin-bottom: 40px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="file"],
input[type="color"] {
  background-color: black;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #191919;
  border-radius: 6px;
}

input[type="color"] {
  padding: 0;
  border: none;
  border-radius: 12px;
  height: 60px;
  width: 100%;
}

.branding-button {
  display: inline-block;
  background-color: #a546fc; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.branding-button:hover {
  background-color: white; 
  color: black; 
  border: 1px solid #4CAF50;
}

.branding-button:disabled {
  background-color: #cccccc; /* Grey background */
  color: #666666; /* Grey text */
  cursor: not-allowed; /* Shows a 'not-allowed' cursor */
}


.blast-email-section {
  margin: 40px 0px;
}

.blast-email-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blast-email-settings {
  flex: 1;
  min-width: 300px; /* Or any suitable width */
  padding: 10px;
}

.email-preview {
  flex: 1;
  min-width: 300px; /* Or any suitable width */
  padding: 0px 90px;
}

/* Responsive Styling */
@media (max-width: 1224px) { /* Or any suitable breakpoint */
  .blast-email-container {
    flex-direction: column;
  }

  .blast-email-settings, .email-preview {
    min-width: 100%;
  }

  .blast-emails-container {
    /* border: 1px solid gray; */
     border-radius: 24px;
     padding: 30px;
     margin: 20px 0px;
     background-color: #121212;
   }
}

/* Button positioning */
.send-button-container {
  margin-top: 20px;
  text-align: center;
}

.blast-email-container .divider {
  width: 1px;               /* Defines the thickness of the divider */
  background-color: #404040;   /* Defines the color of the divider */
  margin: 15px 0;  
  margin: 40px;         /* Provides some spacing around the divider */            /* Ensures the divider stretches the full width */
}

.email-from-name strong,
.email-subject strong {
    display: block;
    margin-bottom: 5px; /* Adjust as needed */
}

.email-from-name div,
.email-subject div {
    margin-bottom: 15px; /* Adjust as needed */
}

.email-from-name input,
.email-subject input {
    margin-left: 0px; 
}

.specific-users-list {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 20px;
  margin-top: 8px;
  background-color: #121212;
}

.specific-users-list input{
display: none;
}

.specific-users-list label::before{
  display: none;
}

.specific-user-item {
  display: flex;
  align-items: left;
  margin-bottom: 8px;
}

.specific-user-item input[type="checkbox"] {
  cursor: pointer;
}

.user-label {
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
  padding: 2px 6px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.specific-user-item input[type="checkbox"]:checked + .user-label {
  background-color: #4CAF50; /* Or any color you prefer */
  color: white;
}

.change-button-txt {
  margin-top: 20px;
  width: 200px;
  background-color: #a546fc;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-button-txt:hover {
  background-color: white;
  color: #a546fc;
}


.fa-lock {
  color: #ccc;
}

.loading-placeholder {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}


.users-page {
  position: relative;
  background-color: black;
  color: white;
  column-gap: 40px;
  margin-top: 75px;
}

.users-content {
  width: 100%;
  max-width: 600px;
}

.users-list {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Content is aligned to the left */
  margin-bottom: 1rem;
  border: 1px solid #494949;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.5s;
  width: 100%; /* Make the li element take the full available width */
}

.user-name {
  flex-grow: 1; /* Take up all available space */
  margin: 0px; /* Optional: Give it some margin for spacing */
}


/* Hover effect for the organizer link */
.user-item:hover {
  cursor: pointer; /* Changes the cursor to a hand, indicating a clickable area */
  background-color: #121212; /* Changes the background color to dark gray when hovering */
  border: 1px solid var(--accent-color);
  transition: all 0.5s;
}

.user-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.user-name {
  font-size: 16px;
}

/* This is to reset the color and remove underlines from the link */
.user-item, .user-item:visited {
  color: inherit; /* Inherits the color from its parent (or any defined color in a higher specificity) */
  text-decoration: none;
  display: flex; /* Using flexbox for alignment */
  align-items: center; /* Vertically center-aligns the content */
}

/* Override the default link color and underline */
.user-link, .user-link:visited {
  color: inherit; /* Inherits the color from its parent or any defined color in higher specificity */
  text-decoration: none; /* Removes underline */
  display: flex;
  align-items: center;
  width: 100%;
}

/* Add a hover effect for the link */
.user-link:hover {
  color: inherit; /* Keeps the color unchanged during hover */
}

/* Notifications Page */

.notifications-page {
  max-width: 600px;
}







































